import React, { useState } from 'react';

import { SettingsIcon, IconButton } from 'shared/icons';
import { SidePanel } from 'shared/ui';

import { WidgetSetting } from './WidgetSetting';

export const UserSettings = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <IconButton onClick={toggleOpenClick}>
        <SettingsIcon />
      </IconButton>
      <SidePanel
        onBtnClose={toggleOpenClick}
        onClose={toggleOpenClick}
        anchor="right"
        title="Настройки пользователя"
        open={isOpen}>
        <WidgetSetting onSave={toggleOpenClick} />
      </SidePanel>
    </>
  );
};
