import {
  CircularProgress,
  CircularProgressProps,
  Button,
  ButtonProps,
  styled,
} from '@material-ui/core';
import React from 'react';

import { UiBox } from 'shared/ui';
import { theme } from 'theme';

export type LoadingButtonProps = {
  isLoading: boolean;
} & ButtonProps;

type StyledLoaderProps = {
  invertColor: boolean;
} & CircularProgressProps;

const Loader = styled(({ invertColor, ...other }: StyledLoaderProps) => (
  <CircularProgress {...other} />
))({
  color: (props: StyledLoaderProps) => (props.invertColor ? '#fff' : theme.palette.primary.main),
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-12px',
  marginLeft: '-12px',
  zIndex: 100,
});

export const LoadingButton = (props: LoadingButtonProps) => {
  const { isLoading, disabled, children, ...buttonProps } = props;

  return (
    <Button disabled={isLoading || disabled} style={{ position: 'relative' }} {...buttonProps}>
      {isLoading && <Loader invertColor={buttonProps.variant === 'contained'} size={24} />}
      <UiBox visibility={isLoading ? 'hidden' : 'visible'}>{children}</UiBox>
    </Button>
  );
};
