import React, { FC } from 'react';

import { PageHeading } from 'components/helpers/PageHeading';
import { Box, Grid, UiFlex } from 'shared/ui';

export const TopPanel: FC = () => {
  return (
    <Box mb={3}>
      <UiFlex justifyContent="space-between">
        <Grid item md={5}>
          <PageHeading text="Уведомления об уборке ДТ" />
        </Grid>
      </UiFlex>
    </Box>
  );
};
