import format from 'date-fns/format';
import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { sendNotificationAddress } from 'api/PrivateOperationController';
import { Button, Grid } from 'shared/ui';
import { sendNotificationAddressParams } from 'types/PrivateOperationController';

import { useAddressSelectedList } from '../context';
import { TimeValue } from '../types';
import { timeListKeys } from '../utils';

import { CleaningDate, CleaningTime, NotifyCategory } from './components';
import { ModalNotifyTs } from './dialog';
import { reducer, ReducerActions } from './reducer';

export const NotifySender: FC = () => {
  const controller = useAddressSelectedList();
  const [state, dispatch] = useReducer(reducer, {});
  const [dialog, setDialog] = useState(false);

  const disabled = useMemo(() => {
    return !controller.list || !Object.keys(controller.list).length;
  }, [controller.list]);

  useEffect(() => {
    if (disabled) {
      dispatch({
        type: ReducerActions.init,
      });
    }
  }, [disabled]);

  const setCleaningDate = useCallback((date: Date | null) => {
    if (date) {
      dispatch({
        type: ReducerActions.setCleaningDate,
        payload: format(date, 'yyyy.MM.dd'),
      });
    }
  }, []);

  const setCleaningType = useCallback((value: string) => {
    dispatch({
      type: ReducerActions.setCleaningType,
      payload: value,
    });
  }, []);

  const setCleaningTimeFrom = useCallback((value: TimeValue['key']) => {
    dispatch({
      type: ReducerActions.setCleaningTimeFrom,
      payload: value,
    });
  }, []);

  const setCleaningTimeTo = useCallback((value: TimeValue['key']) => {
    dispatch({
      type: ReducerActions.setCleaningTimeTo,
      payload: value,
    });
  }, []);

  const openDialog = useCallback(() => {
    setDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialog(false);
  }, []);

  const sendFire = useMemo(() => {
    if (
      !disabled &&
      !!controller.list &&
      state.cleaningDate &&
      state.cleaningTimeFrom &&
      state.cleaningTimeTo &&
      state.cleaningType
    ) {
      return async () => {
        const payload: sendNotificationAddressParams = {
          addresses: Object.values(controller.list!),
          cleaningDate: format(new Date(state.cleaningDate!), 'dd.MM.yyyy'),
          cleaningTimeTo: timeListKeys[state.cleaningTimeTo!],
          cleaningTimeFrom: timeListKeys[state.cleaningTimeFrom!],
          cleaningType: state.cleaningType!,
        };
        await sendNotificationAddress(payload);
        controller.clean?.();
        closeDialog();
      };
    }

    return undefined;
  }, [disabled, state, controller.list, controller.clean]);

  return (
    <>
      <Grid container direction="column" spacing={3} style={{ width: 400, marginLeft: 24 }}>
        <CleaningDate
          disabled={disabled}
          setCleaningDate={setCleaningDate}
          cleaningDate={state.cleaningDate}
        />
        <CleaningTime
          disabled={disabled}
          date={state.cleaningDate}
          to={state.cleaningTimeTo}
          from={state.cleaningTimeFrom}
          setCleaningTimeFrom={setCleaningTimeFrom}
          setCleaningTimeTo={setCleaningTimeTo}
          cleaningDate={state.cleaningDate}
        />
        <NotifyCategory
          disabled={disabled}
          setCleaningType={setCleaningType}
          cleaningType={state.cleaningType}
        />
        <Grid item>
          <Button variant="contained" color="primary" disabled={!sendFire} onClick={openDialog}>
            Отправить
          </Button>
        </Grid>
      </Grid>
      {!!dialog && !!sendFire && <ModalNotifyTs callback={sendFire} close={closeDialog} />}
    </>
  );
};
