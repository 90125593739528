import { action, observable, reaction } from 'mobx';

import { getOrganizationSettingUsingPOST } from '../api/PrivateOperationController';
import { organizationSetting } from '../types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class OrganizationSettingsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    /**
     * Реакция. Запись в localStorage значения organizationSettings
     */
    reaction(
      () => this.organizationSettings,
      (organizationSettings) => {
        if (organizationSettings) {
          window.localStorage.setItem('organizationSettings', JSON.stringify(organizationSettings));
        } else {
          window.localStorage.removeItem('organizationSettings');
        }
      }
    );
  }

  /**
   * Настройки организации
   */
  @observable organizationSettings: organizationSetting = JSON.parse(
    `${window.localStorage.getItem('organizationSettings')}`
  );
  /**
   * Метод записывающий настройки организации
   */
  @action setOrganizationSettings(value: organizationSetting) {
    this.organizationSettings = value;
  }

  /**
   * Метод получающий настройки организации
   */
  @action getOrganizationSettings() {
    return getOrganizationSettingUsingPOST({}).then((response) => {
      if (response.data.code === 1) {
        this.setOrganizationSettings(response.data.organizationSetting!);
      }
    });
  }
}
