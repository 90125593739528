import { Icon, InputAdornment } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { spacing, SpacingProps } from '@material-ui/system';
import { DatePicker as DatePickerMui } from 'mui-rff';
import React from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  name: string;
  format?: string;
  maxDate?: Date;
  required?: boolean;
  style?: Object;
  minDate?: Date;
  disabled?: boolean;
  openTo?: 'year' | 'date' | 'month' | undefined;
} & SpacingProps;

export const StyledDatePicker = styled(DatePickerMui)<Props>`
  ${spacing}
`;

export const MuiFormDatePicker = ({
  label,
  name,
  format = 'dd.MM.yyyy',
  maxDate = new Date(3100, 1, 1),
  required = false,
  style,
  minDate,
  disabled,
  mb = 3,
  openTo,
  ...other
}: Props) => (
  <StyledDatePicker
    {...other}
    mb={mb}
    label={label}
    size="small"
    required={required}
    maxDate={maxDate}
    inputVariant="outlined"
    disableToolbar
    variant="inline"
    format={format}
    name={name}
    minDate={minDate}
    style={style}
    disabled={disabled}
    openTo={openTo}
    InputProps={{
      endAdornment: (
        <InputAdornment position={'end'}>
          <Icon>
            <CalendarToday />
          </Icon>
        </InputAdornment>
      ),
    }}
  />
);
