import axios, { CancelTokenSource } from 'axios';
import { useReducer, useState, useCallback, useEffect, useMemo } from 'react';

import { initialState, reducer, ReducerActions, loaderStep } from './reducer';

export const useLifecicle = (
  /** обратный вызов начала загрузки*/
  loadingCallback: (
    pageTo: number,
    pageFrom: number,
    cancelSource: CancelTokenSource
  ) => Promise<unknown[]>
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    dispatch({
      type: ReducerActions.init,
    });
  }, [loadingCallback]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    if (!!state?.loading && !state?.stopedLoader) {
      const pageFrom = state?.list?.length || 0;
      loadingCallback(loaderStep, pageFrom, cancelSource)
        .then((result) => {
          return dispatch({
            type: ReducerActions.setList,
            payload: {
              list: result,
            },
          });
        })
        .catch(() => {
          setError('ошибка загрузки');
        });
    }

    return () => {
      cancelSource.cancel();
    };
  }, [state?.loading, !state?.stopedLoader, state?.list?.length]);

  const loadingFire = useCallback(() => {
    dispatch({
      type: ReducerActions.loading,
    });
  }, []);

  return useMemo(
    () => ({
      state,
      loadingFire,
      error,
    }),
    [state, error, loadingFire]
  );
};
