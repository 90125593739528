import axios from 'axios';
import hasIn from 'lodash/hasIn';
import { observable, action } from 'mobx';
import { v1 as uuid } from 'uuid';

import { RootStore } from './RootStore';

type Notification = {
  id?: string;
  text?: string;
  details?: string;
  type?: string;
  delay?: number;
};

const excludeNotificationFor = ['/public/authenticate', '/debug/authenticate'];

export default class CommonStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    /**
     * Перехватчик запросов на север.
     * Отлавливает ошибки и записывает в информационное сообщение
     */
    axios.interceptors.response.use(
      (response) => {
        const urlInExcludeList = excludeNotificationFor.some(
          (url) => url.indexOf(response.config.url!) !== -1
        );

        if (response.data.code !== 1 && !urlInExcludeList) {
          this.setNotification({ text: response.data.text, details: response.data.details });
          console.error(response.data.text, response.data.details || '');
        }

        return response;
      },
      (error) => {
        if (hasIn(error, 'response.status')) {
          if (error.response.status === 404) {
            this.setNotification({ text: 'Ошибка соединения' });
            console.error(error.response);
          }
        } else {
          this.setNotification({ text: 'Ошибка соединения' });
          console.error(error);
        }

        return Promise.reject(error);
      }
    );
  }

  ////// Observables

  /**
   * Объект содержащий данные информационного сообщения
   */
  @observable notification: Notification = {
    id: '',
    text: '',
    details: '',
    type: '',
  };

  ////// actions

  /**
   * Метод записывающий данные информационного сообщения
   */
  @action setNotification(notification: Notification) {
    this.notification = {
      ...notification,
      id: uuid(),
    };
  }
}
