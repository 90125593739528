import { Reducer } from 'react';

import type { TNotifyForm, TReducerActions } from '../types';
import { checkTimeTo, checkTimeFromByDate } from '../utils';

export const loaderStep = 10;

export enum ReducerActions {
  init = 'INIT',
  setCleaningDate = 'SET_CLEANING_DATE',
  setCleaningTimeFrom = 'SET_CLEANING_FROM',
  setCleaningTimeTo = 'SET_CLEANING_TO',
  setCleaningType = 'SET_CLEANING_TYPE',
}

type TActionPayload = {
  [ReducerActions.init]: undefined;
  [ReducerActions.setCleaningDate]: string;
  [ReducerActions.setCleaningTimeFrom]: number;
  [ReducerActions.setCleaningTimeTo]: number;
  [ReducerActions.setCleaningType]: string;
};

export const reducer: Reducer<TNotifyForm, TReducerActions<TActionPayload>> = (state, action) => {
  switch (action.type) {
    case ReducerActions.init: {
      return {};
    }

    case ReducerActions.setCleaningDate: {
      if (!state) {
        return state;
      }

      const check = checkTimeFromByDate(action.payload, state.cleaningTimeFrom);

      return {
        ...state,
        cleaningDate: action.payload,
        cleaningTimeFrom: check ? state.cleaningTimeFrom : undefined,
        cleaningTimeTo: check ? state.cleaningTimeTo : undefined,
      };
    }

    case ReducerActions.setCleaningTimeFrom: {
      if (!state) {
        return state;
      }

      const check = checkTimeTo(action.payload, state.cleaningTimeTo);

      return {
        ...state,
        cleaningTimeFrom: action.payload,
        cleaningTimeTo: check ? state.cleaningTimeTo : undefined,
      };
    }

    case ReducerActions.setCleaningTimeTo: {
      if (!state) {
        return state;
      }
      return { ...state, cleaningTimeTo: action.payload };
    }

    case ReducerActions.setCleaningType: {
      if (!state) {
        return state;
      }
      return { ...state, cleaningType: action.payload };
    }

    default:
      return state;
  }
};
