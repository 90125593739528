export interface address {
  building?: string;
  changeDate?: Date;
  construction?: string;
  createDate?: Date;
  houseNumber?: string;
  housing?: string;
  id?: number;
  name?: string;
  shortName?: string;
  zipCode?: string;
}
export interface appointment {
  changeDate?: string;
  createDate?: string;
  id?: number;
  name?: string;
  organization?: organization;
}
export interface authenticateParameter {
  code?: string;
  isGisRd?: boolean;
  login?: string;
  password?: string;
  state?: string;
}

export const Organizations = {
  UO: 'UO',
  FKR: 'FKR',
  MZHI: 'MZHI',
} as const;

export type OrganizationType = typeof Organizations[keyof typeof Organizations];
export interface authenticateResult {
  code?: number;
  details?: string;
  operationDate?: string;
  securityAccount?: securityAccount;
  text?: string;
  token?: string;
  totalCount?: number;
}
export interface dispatcherService {
  address?: address;
  aroundTheClock?: boolean;
  id?: number;
  name?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  phoneNumber3?: string;
}
export interface employee {
  appointment?: appointment;
  confirmed?: boolean;
  email?: string;
  firstName?: string;
  fullResponsibility?: boolean;
  id?: number;
  lastName?: string;
  middleName?: string;
  organization?: organization;
  phoneNumber?: string;
  sudirDepartmentName?: string;
  sudirPositionName?: string;
  organizationType?: OrganizationType;
}
export interface getCommonConfigurationResult {
  code?: number;
  details?: string;
  operationDate?: string;
  sudirAuthenticationUrl?: string;
  sudirLogoutUrl?: string;
  text?: string;
  totalCount?: number;
}
export interface getTokenTtlParameter {
  pageNumber?: number;
  pageSize?: number;
  token?: string;
}
export interface getTokenTtlResult {
  code?: number;
  details?: string;
  operationDate?: string;
  text?: string;
  tokenDateExpiration?: string;
  totalCount?: number;
}
export interface gisrdFile {
  fileName?: string;
  fileType?: string;
  hash?: string;
  id?: number;
  registrationDate?: Date;
  reportDate?: Date;
  size?: number;
  url?: string;
}
export interface license {
  appendix?: gisrdFile;
  file?: gisrdFile;
  id?: number;
  issuer?: string;
  licenseNumber?: string;
  name?: string;
  receiveDate?: Date;
}
export interface logoutParameter {
  token?: string;
}
export interface operationResult {
  code?: number;
  details?: string;
  operationDate?: string;
  text?: string;
  totalCount?: number;
}
export interface organization {
  adminStaffCount?: number;
  aggrementComment?: string;
  auditChecked?: boolean;
  auditDisabled?: boolean;
  audits?: Array<gisrdFile>;
  changeDate?: Date;
  createDate?: Date;
  dispatcherServices?: Array<dispatcherService>;
  dispatchingServiceAddress?: string;
  dispatchingServicePhone?: string;
  dsOperatingFriFromTime?: Date;
  dsOperatingFriIsOutput?: boolean;
  dsOperatingFriToTime?: Date;
  dsOperatingIsRoundClock?: boolean;
  dsOperatingMonFromTime?: Date;
  dsOperatingMonIsOutput?: boolean;
  dsOperatingMonToTime?: Date;
  dsOperatingSatFromTime?: Date;
  dsOperatingSatIsOutput?: boolean;
  dsOperatingSatToTime?: Date;
  dsOperatingSunFromTime?: Date;
  dsOperatingSunIsOutput?: boolean;
  dsOperatingSunToTime?: Date;
  dsOperatingThuFromTime?: Date;
  dsOperatingThuIsOutput?: boolean;
  dsOperatingThuToTime?: Date;
  dsOperatingTueFromTime?: Date;
  dsOperatingTueIsOutput?: boolean;
  dsOperatingTueToTime?: Date;
  dsOperatingWedFromTime?: Date;
  dsOperatingWedIsOutput?: boolean;
  dsOperatingWedToTime?: Date;
  email?: string;
  engineerStaffCount?: number;
  factualAddress?: string;
  fax?: string;
  headFirstName?: string;
  headMiddleName?: string;
  headPhoneNumber?: string;
  headPhoneNumberPlus?: string;
  headPlace?: string;
  headPosition?: string;
  headSecondName?: string;
  houseCount?: number;
  id?: number;
  inAssociation?: boolean;
  inn?: string;
  juridicalAddress?: string;
  koapCase?: boolean;
  kpp?: string;
  licenseAbsenceDate?: Date;
  licenses?: Array<license>;
  modelId?: number;
  municipalityShare?: number;
  name?: string;
  notAssociationDate?: Date;
  officialSite?: string;
  ogrn?: string;
  ogrnDate?: Date;
  ogrnRegistrar?: string;
  operating?: string;
  operatingFriDinnerFromTime?: Date;
  operatingFriDinnerIsOutput?: boolean;
  operatingFriDinnerToTime?: Date;
  operatingFriFromTime?: Date;
  operatingFriToTime?: Date;
  operatingMonDinnerFromTime?: Date;
  operatingMonDinnerIsOutput?: boolean;
  operatingMonDinnerToTime?: Date;
  operatingMonFromTime?: Date;
  operatingMonToTime?: Date;
  operatingSatDinnerFromTime?: Date;
  operatingSatDinnerIsOutput?: boolean;
  operatingSatDinnerToTime?: Date;
  operatingSatFromTime?: Date;
  operatingSatToTime?: Date;
  operatingSunDinnerFromTime?: Date;
  operatingSunDinnerToTime?: Date;
  operatingSunFromTime?: Date;
  operatingSunIsOutput?: boolean;
  operatingSunToTime?: Date;
  operatingThuDinnerFromTime?: Date;
  operatingThuDinnerIsOutput?: boolean;
  operatingThuDinnerToTime?: Date;
  operatingThuFromTime?: Date;
  operatingThuToTime?: Date;
  operatingTueDinnerFromTime?: Date;
  operatingTueDinnerIsOutput?: boolean;
  operatingTueDinnerToTime?: Date;
  operatingTueFromTime?: Date;
  operatingTueToTime?: Date;
  operatingWedDinnerFromTime?: Date;
  operatingWedDinnerIsOutput?: boolean;
  operatingWedDinnerToTime?: Date;
  operatingWedFromTime?: Date;
  operatingWedToTime?: Date;
  opf?: string;
  organizationSite?: string;
  organizationType?: string;
  partyShare?: number;
  phoneNumber?: string;
  postAddress?: string;
  receiveCitizensFriEmpPos?: string;
  receiveCitizensFriFromTime?: Date;
  receiveCitizensFriIsOutput?: boolean;
  receiveCitizensFriToTime?: Date;
  receiveCitizensMonEmpPos?: string;
  receiveCitizensMonFromTime?: Date;
  receiveCitizensMonIsOutput?: boolean;
  receiveCitizensMonToTime?: Date;
  receiveCitizensSatEmpPos?: string;
  receiveCitizensSatFromTime?: Date;
  receiveCitizensSatIsOutput?: boolean;
  receiveCitizensSatToTime?: Date;
  receiveCitizensSunEmpPos?: string;
  receiveCitizensSunFromTime?: Date;
  receiveCitizensSunIsOutput?: boolean;
  receiveCitizensSunToTime?: Date;
  receiveCitizensThuEmpPos?: string;
  receiveCitizensThuFromTime?: Date;
  receiveCitizensThuIsOutput?: boolean;
  receiveCitizensThuToTime?: Date;
  receiveCitizensTueEmpPos?: string;
  receiveCitizensTueFromTime?: Date;
  receiveCitizensTueIsOutput?: boolean;
  receiveCitizensTueToTime?: Date;
  receiveCitizensWedEmpPos?: string;
  receiveCitizensWedFromTime?: Date;
  receiveCitizensWedIsOutput?: boolean;
  receiveCitizensWedToTime?: Date;
  shortName?: string;
  square?: number;
  staffCount?: number;
  usn?: boolean;
  violations?: Array<violation>;
  workingStaffCount?: number;
}
export interface refreshTokenParameter {
  pageNumber?: number;
  pageSize?: number;
  token?: string;
}
export interface refreshTokenResult {
  code?: number;
  details?: string;
  operationDate?: string;
  text?: string;
  tokenDateExpiration?: string;
  totalCount?: number;
}
export interface securityAccount {
  changeDate?: string;
  createDate?: string;
  employee?: employee;
  expireDate?: string;
  id?: number;
  isGisRd?: boolean;
  isLocked?: boolean;
  lockDate?: string;
  lockReason?: string;
  login?: string;
  password?: string;
  roles?: Array<securityRole>;
}
export interface securityRole {
  code?: string;
  id?: number;
  name?: string;
}
export interface violation {
  attractionDate?: Date;
  controlAgency?: string;
  docDate?: Date;
  docNumber?: string;
  docType?: string;
  eliminationEvent?: string;
  file?: gisrdFile;
  firstName?: string;
  id?: number;
  impactResult?: string;
  lastName?: string;
  measure?: string;
  middleName?: string;
  name?: string;
  penaltySum?: number;
  position?: string;
  reportDate?: Date;
  violationCount?: number;
  violationType?: violationType;
  violatorType?: string;
}
export interface violationType {
  id?: number;
  koapArticle?: string;
  name?: string;
  periodFrom?: Date;
  periodTo?: Date;
  sortOrder?: number;
}
