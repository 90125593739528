import { MAXIMAL_PERIOD_TO, MINIMAL_PERIOD_FROM } from 'containers/TreatmentsRegistry/constants';

const changeCommaToSemicolon = (text: string | number) => {
  if (typeof text === 'string') {
    return text.replaceAll(',', ';');
  }

  return text;
};

export const changeSemicolonToComma = (text: string) => text.replaceAll(';', ',');

export const paramsSerializerArrToSameList = (params: Record<string, any>) => {
  const result: string[] = [];

  Object.keys(params).forEach((key) => {
    if (!Array.isArray(params[key])) {
      if ([MAXIMAL_PERIOD_TO, MINIMAL_PERIOD_FROM].includes(params[key])) return;

      result.push(`${key}=${params[key]}`);

      return;
    }

    const fromArrParam = params[key]
      .map((item: string) => `${key}=${changeCommaToSemicolon(item)}`)
      .join('&');
    result.push(fromArrParam);
  });

  return result.join('&');
};
