import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import {
  ChevronRightIcon,
  IconButton,
  NotificationsIcon,
  MailIcon,
  GroupIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  DashboardIcon,
  ChromeReaderModeIcon,
  AssessmentIcon,
} from 'shared/icons';
import { PermissibleRender } from 'shared/layout';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  UiBox,
  UiTypography,
  UiFlex,
  CircularProgress,
} from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';
import { Organizations } from 'types/publicController';

import { useUserInstructions } from './hooks/useUserInstructions';
import { Link, ExpandButton, StyledInstructionButton } from './styled';

type MenuItemProps = {
  title: string;
  link: string;
  Icon?: JSX.Element;
  items?: MenuItemProps[];
  exact?: boolean;
  permissions: Array<string>;
};

// Меню для УО
const menuItemsUO: Array<MenuItemProps> = [
  {
    link: '/',
    Icon: <DashboardIcon />,
    exact: true,
    title: 'Рабочий стол',
    permissions: ['DEFAULT'],
  },
  {
    link: '/news',
    Icon: <ChromeReaderModeIcon />,
    title: 'Новости',
    permissions: ['UK_NEWS_READ'],
  },
  {
    link: '/treatments',
    Icon: <GroupIcon />,
    title: 'Обращения жителей',
    permissions: ['UK_TREATMENTS_READ'],
  },
  {
    link: '/soesg-issues',
    Icon: <MailIcon />,
    title: 'Заявки "Наш Город"',
    permissions: ['UK_ISSUES_READ'],
  },
  {
    link: '/polls-oss',
    Icon: <AssessmentIcon />,
    title: 'Опросы и ОСС',
    permissions: ['UK_OSS_READ'],
    items: [
      {
        link: '/oss-protocols',
        title: 'Протоколы ОСС',
        permissions: ['UK_PROTOCOL_OSS_READ'],
      },
    ],
  },
  {
    link: '/notifications-ts',
    Icon: <NotificationsIcon />,
    title: 'Уведомления ТС',
    permissions: ['UK_ISSUES_READ'],
  },
];

// Меню для ФКР
const menuItemsFKR: Array<MenuItemProps> = [
  {
    link: '/',
    Icon: <DashboardIcon />,
    exact: true,
    title: 'Рабочий стол',
    permissions: ['DEFAULT'],
  },
  // Скрыто до дальнейшего распоряжения
  // {
  //   link: '/news',
  //   Icon: <ChromeReaderModeIcon />,
  //   title: 'Новости',
  //   permissions: ['UK_NEWS_READ'],
  // },
  {
    link: '/treatments',
    Icon: <GroupIcon />,
    title: 'Обращения',
    permissions: ['FKR_TREATMENTS_READ'],
  },
  {
    link: '/polls-oss',
    Icon: <AssessmentIcon />,
    title: 'ОСС',
    permissions: ['DEFAULT'],
  },
];

// Меню для МЖИ
const menuItemsMZHI: Array<MenuItemProps> = [
  // TO DO: Новости будут реализованы в следующей итерации
  // {
  //   link: '/news',
  //   Icon: <ChromeReaderModeIcon />,
  //   title: 'Новости',
  //   permissions: ['UK_NEWS_READ'],
  // },
  {
    link: '/polls-oss',
    Icon: <AssessmentIcon />,
    title: 'ОСС',
    permissions: ['DEFAULT'],
  },
];

const MenuItem = (props: MenuItemProps) => {
  const { authStore } = useContext(rootStoreContext);
  const { requiredPermissions } = authStore;
  const { title, link, exact, Icon, permissions, items = [] } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(true);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <PermissibleRender userPermissions={permissions} requiredPermissions={requiredPermissions}>
      <UiBox position="relative">
        <Link exact={exact} to={link} activeClassName="active">
          <ListItem button>
            {!!Icon && <ListItemIcon>{Icon}</ListItemIcon>}
            <ListItemText primary={title} disableTypography inset={!Icon} />
          </ListItem>
        </Link>
        <IconButton
          style={{
            position: 'absolute',
            right: 12,
            top: 8,
          }}
          onClick={handleClick}
          size="small">
          {isExpandable && !open && <ExpandMoreIcon fontSize="small" />}
          {isExpandable && open && <ExpandLessIcon fontSize="small" />}
        </IconButton>
      </UiBox>
    </PermissibleRender>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <PermissibleRender
            key={index}
            userPermissions={item.permissions}
            requiredPermissions={requiredPermissions}>
            <MenuItem {...item} />
          </PermissibleRender>
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const menu = {
  [Organizations.UO]: menuItemsUO,
  [Organizations.FKR]: menuItemsFKR,
  [Organizations.MZHI]: menuItemsMZHI,
} as const;

// const manualLinks = {
//   [Organizations.UO]: '/documents/manual.pdf',
//   [Organizations.FKR]: '/documents/manual_FKR.pdf',
//   [Organizations.MZHI]: '/documents/manual_MZHI.pdf',
// } as const;

export const SideMenu = withRouter(
  observer((props: RouteComponentProps) => {
    const { authStore, userSettingsStore, commonStore } = useContext(rootStoreContext);
    const { isLoggedIn, organizationType } = authStore;
    const { sideMenuIsCollapsed } = userSettingsStore;

    const menuItems = menu[organizationType];

    const { onUserInstructionsClick, isLoading } = useUserInstructions(() =>
      commonStore.setNotification({ text: 'Ссылка не найдена' })
    );

    if (isLoggedIn) {
      return (
        <>
          <Drawer variant="persistent" open={!sideMenuIsCollapsed} style={{ zIndex: 1000 }}>
            <UiFlex flexDirection="column" justifyContent="space-between" height="100%" width={280}>
              <UiBox mt="88px">
                <List component="nav" disablePadding>
                  {menuItems.map((item: MenuItemProps, index) => (
                    <MenuItem {...item} key={index} />
                  ))}
                </List>
              </UiBox>
              <UiBox p="16px 32px" mt="32px">
                <StyledInstructionButton type="button" onClick={onUserInstructionsClick}>
                  <span>
                    <p>Инструкция пользователя</p>
                    {isLoading && <CircularProgress size={10} />}
                  </span>
                </StyledInstructionButton>
                <Divider style={{ margin: '4px 0' }} light />
                <UiTypography variant="body2">
                  Обращение в&nbsp;
                  <a
                    style={{ fontSize: '12px', lineHeight: '12px' }}
                    href="mailto:support@ed.mos.ru">
                    Службу поддержки
                  </a>
                  <br />
                  (support@ed.mos.ru)
                </UiTypography>
              </UiBox>
            </UiFlex>
          </Drawer>
          <ExpandButton
            expanded={!sideMenuIsCollapsed}
            onClick={() => userSettingsStore.toggleSideMenuIsOpen()}>
            <ChevronRightIcon />
          </ExpandButton>
        </>
      );
    }
    return null;
  })
);
