import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { PermissibleRender } from 'shared/layout';
import { rootStoreContext } from 'stores/RootStore';

const PrivateRoute = observer((props: RouteProps & { userPermissions: Array<string> }) => {
  const { authStore } = useContext(rootStoreContext);
  const { ...restProps } = props;

  if (authStore.isLoggedIn)
    return (
      <PermissibleRender
        userPermissions={props.userPermissions}
        requiredPermissions={authStore.requiredPermissions}>
        <Route {...restProps} />
      </PermissibleRender>
    );
  return <Redirect to="/login" />;
});

export default PrivateRoute;
