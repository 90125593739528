import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { UiBox } from '../layout';
import { UiTypography } from '../typo';

export interface DictionaryProps {
  title: string | ReactNode;
  value: string | ReactNode;
  defaultValue?: string | ReactNode;
  mb?: string | number;
  mr?: string | number;
  titleWidth?: number;
}

const DictionaryVContainer = styled(UiBox)`
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const DictionaryV = (props: DictionaryProps) => {
  const { title, value, defaultValue = '', mb = 3, mr = 7 } = props;
  return (
    <DictionaryVContainer display="flex" flexDirection="column" mb={mb} mr={mr}>
      <UiTypography
        color="custom.grayDark"
        minWidth="160px"
        mb={1}
        fontSize="0.75rem"
        lineHeight="1rem"
        as="span">
        {title}
      </UiTypography>
      <UiTypography as="span">{value || defaultValue}</UiTypography>
    </DictionaryVContainer>
  );
};

const DictionaryHContainer = styled(UiBox)`
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const DictionaryH = (props: DictionaryProps) => {
  const { title, value, defaultValue = '', mb = 2, mr = 0, titleWidth = 280 } = props;

  return (
    <DictionaryHContainer display="flex" mb={mb} mr={mr}>
      <UiTypography color="custom.grayDark" minWidth={`${titleWidth}px`} mr={3} as="span">
        {title}
      </UiTypography>
      <UiTypography as="span">{value || defaultValue}</UiTypography>
    </DictionaryHContainer>
  );
};
