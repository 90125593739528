import React, { Component } from 'react';
import styled from 'styled-components';

// Область прилипающая к нижней части экрана, в нее кладутся все тосты
export default class ToastContainer extends Component {
  public render() {
    return <Container>{this.props.children}</Container>;
  }
}

const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: 9999999;
  bottom: 5px;
  left: 0;
`;
