import { styled } from '@material-ui/core';
import React, { FC, useCallback, forwardRef } from 'react';
import { Virtuoso, Components } from 'react-virtuoso';

import { CheckBoxOutlineBlankIcon, CheckBoxIcon } from 'shared/icons';
import { Box, Grid, List, ListItem, UiTypography } from 'shared/ui';
import { NotificationsTsAddresses } from 'types/PrivateOperationController';

import { useAddressSelectedCount, useAddressSelectedItem } from '../context';
import { TListState } from '../types';

const listEmpty: NotificationsTsAddresses[] = [];

const ListContainer: Components['List'] = forwardRef(({ style, children }, ref) => {
  return (
    <List style={{ ...style }} component="div" ref={ref}>
      {children}
    </List>
  );
});

const ListItemStyled = styled(ListItem)(
  ({ theme }) => ({
    // @ts-ignore: проблема с типами темы?
    backgroundColor: theme.palette.custom.dashboardBg,
    padding: '4px 16px',
    gap: 16,
  })
  // columnFixed
);

const ListItemDivider = styled('div')({
  width: '100%',
  height: 2,
});

const AddressFinderItem: FC<NotificationsTsAddresses & { divider: boolean }> = ({
  divider,
  unom,
  address,
}) => {
  const controller = useAddressSelectedItem(unom);

  const callback = useCallback(() => {
    controller?.callback?.({ unom, address });
  }, [controller?.callback, controller?.selected]);

  return (
    <>
      {!!divider && <ListItemDivider />}
      <ListItemStyled button onClick={callback}>
        <Grid container spacing={1} alignItems="center">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 42,
              height: 42,
            }}>
            {!!controller.selected ? (
              <CheckBoxIcon color="primary" />
            ) : (
              <CheckBoxOutlineBlankIcon color="primary" />
            )}
          </Box>
          <Box style={{ width: 16 }} />
          <UiTypography
            variant="body1"
            color="text.primary"
            lineHeight="21px"
            style={{ width: 52 }}>
            {unom}
          </UiTypography>
          <Box style={{ width: 16 }} />
          <UiTypography variant="body1" color="text.primary" lineHeight="21px" style={{ flex: 1 }}>
            {address}
          </UiTypography>
        </Grid>
      </ListItemStyled>
      <ListItemDivider />
    </>
  );
};

type Props = {
  state: TListState | undefined;
  loadingFire: () => void;
  error: string | undefined;
  search?: string;
};

export const AddressFinderList: FC<Props> = ({ state, loadingFire, error, search }) => {
  if (!!search && search?.length < 3) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <UiTypography color="custom.grayDark" lineHeight="21px">
          Введите не менее 3-х символов
        </UiTypography>
      </div>
    );
  }

  if (!state?.list && !!state?.loading) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <UiTypography color="custom.grayDark" lineHeight="21px">
          Поиск...
        </UiTypography>
      </div>
    );
  }

  if (!state?.list?.length && !state?.loading) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <UiTypography color="custom.grayDark" lineHeight="21px">
          Ничего не найдено
        </UiTypography>
      </div>
    );
  }

  return (
    <Virtuoso
      style={{ height: '100%', width: '100%', margin: '10px 0' }}
      data={state?.list as NotificationsTsAddresses[]}
      endReached={loadingFire}
      overscan={50}
      components={{
        List: ListContainer,
      }}
      // data={state?.list as NotificationsTsAddresses[] | undefined}
      itemContent={(index, item: NotificationsTsAddresses) => (
        <AddressFinderItem key={item.unom} {...item} divider={!!index} />
      )}
    />
  );
};

export const AddressFinderCount = () => {
  const count = useAddressSelectedCount();

  return (
    <Box style={{ marginTop: 2 }}>
      <UiTypography color="custom.grayDark" lineHeight="21px">
        Выбрано: {count}
      </UiTypography>
    </Box>
  );
};
