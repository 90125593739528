import React, { useMemo, useContext, FC, createContext } from 'react';

import { NotificationsTsAddresses } from 'types/PrivateOperationController';

type TAddressSelectedContext = {
  selectedList: Record<string, NotificationsTsAddresses>;
  clean: () => void;
  callback: (item: NotificationsTsAddresses) => void;
};

const AddressSelectedContext = createContext<TAddressSelectedContext | undefined>(undefined);

export const AddressSelectedConsumer: FC<TAddressSelectedContext> = ({
  selectedList,
  callback,
  clean,
  children,
}) => {
  const value = useMemo(
    () => ({
      selectedList,
      callback,
      clean,
    }),
    [selectedList, callback, clean]
  );
  //   return null;
  return (
    <AddressSelectedContext.Provider value={value}>{children}</AddressSelectedContext.Provider>
  );
};

export const useAddressSelectedItem = (unom: NotificationsTsAddresses['unom']) => {
  const context = useContext(AddressSelectedContext);

  return useMemo(
    () => ({
      selected: !!context?.selectedList[unom],
      callback: context?.callback,
    }),
    [context?.selectedList, context?.callback]
  );
};

export const useAddressSelectedList = () => {
  const context = useContext(AddressSelectedContext);

  return useMemo(
    () => ({
      list: context?.selectedList,
      clean: context?.clean,
    }),
    [context?.selectedList, context?.clean]
  );
};

export const useAddressSelectedCount = () => {
  const context = useContext(AddressSelectedContext);

  return useMemo(
    () => (!!context?.selectedList ? Object.keys(context?.selectedList).length || 0 : 0),
    [context?.selectedList]
  );
};
