import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styled from 'styled-components';

export const Scrollbar = styled(PerfectScrollbar)`
  .ps__rail-x,
  .ps__rail-y {
    z-index: 2;
  }
`;
