import { observer } from 'mobx-react-lite';
import React, { MouseEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { AccountCircle, IconButton } from 'shared/icons';
import { PermissibleRender } from 'shared/layout';
import { Menu, MenuItem } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';
import { Organizations } from 'types/publicController';

export const ProfileMenu = observer(() => {
  const { authStore } = useContext(rootStoreContext);
  const { organizationType } = authStore;
  const isUO = organizationType === Organizations.UO;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(anchorEl);
  const profileMenuId = 'ed-profile-menu';

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRedirectClick = (path: string) => {
    history.push(path);
  };

  const handleLogout = () => {
    authStore.logout();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-controls={profileMenuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}>
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id={profileMenuId}
        keepMounted
        open={isProfileMenuOpen}
        onClose={handleMenuClose}>
        {/* Временно скрыто */
        /* <MenuItem
          onClick={() => {
            handleRedirectClick('/operator-details');
            handleMenuClose();
          }}>
          Данные представителя организации
        </MenuItem>*/}
        <MenuItem
          onClick={() => {
            handleRedirectClick('/organization-details');
            handleMenuClose();
          }}>
          Информация об организации
        </MenuItem>
        {isUO && (
          <PermissibleRender
            userPermissions={['UK_EMPLOYEE_RESPONSIBILITY']}
            requiredPermissions={authStore.requiredPermissions}>
            <MenuItem
              onClick={() => {
                handleRedirectClick('/users');
                handleMenuClose();
              }}>
              Управление учетными записями пользователей
            </MenuItem>
          </PermissibleRender>
        )}
        <MenuItem
          onClick={() => {
            handleLogout();
            handleMenuClose();
          }}>
          Выход
        </MenuItem>
      </Menu>
    </>
  );
});
