import { action, observable, runInAction } from 'mobx';

import {
  getSoesgIssuesUsingPOST,
  getSoesgIssueDetailsUsingPOST,
} from '../api/PrivateOperationController';
import { getSoesgIssuesParameter, soesgIssue } from '../types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class SOESGIssuesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  //////// Observables

  /**
   * Список заявок НГ
   */
  @observable soesgIssuesList: Array<soesgIssue> = [];

  /**
   * Общее количество всех заявок НГ
   */
  @observable soesgIssuesTotalCount: number = 0;

  /**
   * Детальная информация о заявке НГ
   */
  @observable soesgIssueDetails: soesgIssue = {};

  //////// Actions

  /**
   * Метод записываю детальную информацию о заявке НГ
   * @param value
   */
  @action setSOESGIssueDetails(value: soesgIssue) {
    this.soesgIssueDetails = value;
  }

  /**
   * Метод по получению детальной информации о заявках НГ
   * @param ids
   */
  @action getSOESGIssueDetails(ids: Array<string>) {
    getSoesgIssueDetailsUsingPOST({ ids }).then((response) => {
      if (response.data.code === 1) {
        this.setSOESGIssueDetails(response.data.issues![0]);
      }
    });
  }

  /**
   * Метод по получение списка заявок НГ
   * @param searchParams
   */
  @action getSOESGIssues(searchParams: getSoesgIssuesParameter) {
    getSoesgIssuesUsingPOST(searchParams).then((response) => {
      if (response.data.code === 1) {
        runInAction(() => {
          this.soesgIssuesList = response.data.items!;
          this.soesgIssuesTotalCount = response.data.totalCount!;
        });
      }
    });
  }
}
