import { Paper as MuiPaper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  compose,
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from '@material-ui/system';
import React from 'react';

export const UiBox = styled('div')(
  compose(borders, display, flexbox, palette, positions, shadows, sizing, spacing, typography)
);

export const UiPaper = styled(MuiPaper)(compose(palette, spacing, display, flexbox, sizing));

export const UiFlex = (props: React.ComponentProps<typeof UiBox>) => (
  <UiBox display="flex" {...props} />
);
