import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background: #FFF;
  font-size: 15px;
  color: #1B2A32;;
  font-family:  -apple-system, BlinkMacSystemFont, HelveticaNeue, sans-serif;
    h1, h2, h3, h4, h5, h6 {
    font-family: "SFProDisplay-Bold", Arial, Verdana, sans-serif;
    color: #526D7A;
    letter-spacing: 0.7px;
  }
  h1 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 14px 0;
  }
  h2 {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 15px 0;
  }

  a {
    color: #39C7DE;
    text-decoration: none;
    font-size: 15px;
    line-height: 24px;
  }
  p {
    font-size: 15px;
    line-height: 24px;
    color: #1B2A32;
  }
  b {
    font-weight: Bold;
    font-size: 15px;
    line-height: 24px;
    color: #1B2A32;
  }
  blockquote {
  font-style: italic;
  margin: 16px 0;
  font-size: 15px;
  line-height: 24px;
  color: #526D7A;
  padding-left: 16px;
  }
  ul {
    line-height: 1.8;
    list-style: none;
    padding: 0;
  }

  hr {
    display: block; height: 1px;
    border: 0; border-top: 1px solid #D8D8D8;
    margin: 1em 0; padding: 0;
  }

  #root {
    min-height: 100%;
    height: 100%;
  }
  .small {
    font-size: 12px;
    line-height: 16px;
    color: #526D7A;
   }

   // TODO Временное решение. Переделать как будет ясно
   .MuiAutocomplete-popper {
    position: relative;
   }
   
   .dateInput {
     .MuiFormControl-root {
       width: 100%;
     }
     .MuiIconButton-root {
       right: -12px;
     }
   }
}
`;
