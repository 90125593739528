import { styled } from '@material-ui/core/styles';
import React from 'react';

import { CircularProgress } from 'shared/ui';

import { UiBox } from '../layout';
type LoadingBoxProps = {
  isLoading: boolean;
  height?: string;
} & React.ComponentProps<typeof UiBox>;

const Loader = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-20px',
  marginLeft: '-20px',
  zIndex: 100,
  visibility: 'visible',
});

export const LoadingBox = (props: LoadingBoxProps) => {
  const { isLoading, children, height, ...boxProps } = props;
  return (
    <UiBox
      position="relative"
      visibility={isLoading ? 'hidden' : 'visible'}
      height={height || 'auto'}
      {...boxProps}>
      {isLoading && <Loader />}
      {children}
    </UiBox>
  );
};
