import { toJS } from 'mobx';
import { createContext } from 'react';

import AddressStore from './AddressStore';
import AuthStore from './AuthStore';
import CommonStore from './CommonStore';
import NewsStore from './NewsStore';
import OrganizationSettingsStore from './OrganizationSettingsStore';
import SOESGIssuesStore from './SOESGIssuesStore';
import TreatmentsStore from './TreatmentsStore';
import UserSettingsStore from './UserSettingsStore';
import UsersStore from './UsersStore';

export class RootStore {
  authStore = new AuthStore(this);
  userSettingsStore = new UserSettingsStore(this);
  addressStore = new AddressStore(this);
  organizationSettingsStore = new OrganizationSettingsStore(this);
  commonStore = new CommonStore(this);
  usersStore = new UsersStore(this);
  treatmentsStore = new TreatmentsStore(this);
  newsStore = new NewsStore(this);
  soesgIssuesStore = new SOESGIssuesStore(this);
}

const rootStoreInstance = new RootStore();
export const rootStoreContext = createContext(rootStoreInstance);

(window as any)._____APP_STATE_____ = toJS(rootStoreInstance);
