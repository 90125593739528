import { FilledInputProps } from '@material-ui/core';
import { spacing, SpacingProps } from '@material-ui/system';
import { TextField } from 'mui-rff';
import React from 'react';
import styled from 'styled-components';

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  multiline?: boolean;
  inputProps?: Partial<FilledInputProps>;
  style?: Object;
  fieldProps: Object;
} & SpacingProps;

export const StyledFieldInput = styled(TextField)<Props>`
  ${spacing}
`;

export const FieldInput = ({
  inputProps,
  label,
  name,
  required = false,
  style,
  mb = 3,
  fieldProps,
  ...other
}: Props) => {
  return (
    <StyledFieldInput
      {...other}
      mb={mb}
      name={name}
      required={required}
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      style={style}
      fieldProps={fieldProps}
    />
  );
};
