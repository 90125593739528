import { getTreatmentsGetParameter, treatmentV2 } from 'types/PrivateOperationController';

import { FilterKeys } from './types';

export const DATES_TO_CHECK_RANGE: Record<string, string> = {
  ['CREATEDATE']: 'createDate',
  ['REPLYDATE']: 'replyDate',
  ['CLOSEDATE']: 'closeDate',
};

export const MAXIMAL_PERIOD_TO = '2100-01-01T00:00:00';
export const MINIMAL_PERIOD_FROM = '1980-01-01T00:00:00';

export const arrayTypeFilters: FilterKeys[] = [
  'statuses',
  'titles',
  'rating',
  'applicants',
  'assignees',
  'processingTimes',
  'replyCounts',
];

export const defaultFilter: getTreatmentsGetParameter = {
  search: null,
  createDatePeriodFrom: null,
  createDatePeriodTo: null,
  replyDatePeriodFrom: null,
  replyDatePeriodTo: null,
  closeDatePeriodFrom: null,
  closeDatePeriodTo: null,
  statuses: null,
  titles: null,
  rating: null,
  applicants: null,
  assignees: null,
  processingTimes: null,
  replyCounts: null,
  externalIdFrom: null,
  externalIdTo: null,
};

export const emptyArray: treatmentV2[] = [];
