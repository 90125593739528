import { Drawer, DrawerProps } from '@material-ui/core';
import React from 'react';

import { CloseIcon, IconButton } from 'shared/icons';
import { UiBox, UiPaper, UiTypography } from 'shared/ui';

type Props = {
  title?: string;
  onBtnClose?: () => void;
} & DrawerProps;

export const SidePanel = ({ title, children, onBtnClose, ...other }: Props) => {
  return (
    <Drawer {...other}>
      <UiBox width="368px">
        <UiPaper
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="8px 8px 8px 24px ">
          <UiTypography variant="body1" fontWeight={600}>
            {title}
          </UiTypography>
          {onBtnClose && (
            <IconButton onClick={() => onBtnClose()}>
              <CloseIcon />
            </IconButton>
          )}
        </UiPaper>
        <UiBox p="24px">{children}</UiBox>
      </UiBox>
    </Drawer>
  );
};
