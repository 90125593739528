import axios from 'axios';
import hasIn from 'lodash/hasIn';
import { observable, action, reaction, runInAction, computed } from 'mobx';

import { authenticateUsingPOST as authenticateUsingPOSTDebug } from 'api/DebugController';
import {
  authenticateUsingPOST,
  getCommonConfigurationUsingPOST,
  getTokenTtlUsingPOST,
  logoutUsingPOST,
  refreshTokenUsingPOST,
} from 'api/PublicController';
import {
  OrganizationType,
  Organizations,
  authenticateParameter,
  securityAccount,
} from 'types/publicController';
import { getCookie, setCookie } from 'utils/cookie';

import { RootStore } from './RootStore';

export default class AuthStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    /**
     * Реакция. Запись в sessionStorage значения isLoggedIn
     */
    reaction(
      () => this.isLoggedIn,
      (isLoggedIn: boolean) => {
        window.localStorage.removeItem('isLoggedIn');
        if (isLoggedIn) {
          window.sessionStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
        } else {
          window.sessionStorage.removeItem('isLoggedIn');
        }
      }
    );

    /**
     * Реакция. Запись в localStorage значения isLoggedIn
     */
    reaction(
      () => this.isConfirmed,
      (isConfirmed: boolean) => {
        if (isConfirmed) {
          window.localStorage.setItem('isConfirmed', JSON.stringify(isConfirmed));
        } else {
          window.localStorage.removeItem('isConfirmed');
        }
      }
    );

    /**
     * Реакция. Запись в cookie значения token
     */
    reaction(
      () => this.token,
      (token: string | undefined) => {
        if (token) {
          setCookie('OFFICETOKEN', token, {});
        }
      }
    );

    /**
     * Реакция. Запись в localStorage значения securityAccount
     */
    reaction(
      () => this.securityAccount,
      (securityAccount) => {
        if (securityAccount) {
          window.localStorage.setItem('securityAccount', JSON.stringify(securityAccount));
        } else {
          window.localStorage.removeItem('securityAccount');
        }
      }
    );

    /**
     * Реакция. Запись в localStorage ссылок на авторизацию в СУДИР
     */
    reaction(
      () => this.sudirAuthUrls,
      (sudirAuthUrls) => {
        if (sudirAuthUrls) {
          window.localStorage.setItem('sudirAuthUrls', JSON.stringify(sudirAuthUrls));
        } else {
          window.localStorage.removeItem('sudirAuthUrls');
        }
      }
    );

    /**
     * Реакция. Запись в localStorage информации о типе организации
     */
    reaction(
      () => this.organizationType,
      (organizationType: OrganizationType) => {
        if (organizationType) {
          window.localStorage.setItem('organizationType', JSON.stringify(organizationType));
        } else {
          window.localStorage.removeItem('organizationType');
        }
      }
    );

    /**
     * Перехватчик запросов на север.
     * Добавляет значение токена в заголовок
     * Убран для устранения уязвимости. Теперь передаем через cookie
     */
    axios.interceptors.request.use(
      (config) => {
        if (!config.headers?.OFFICETOKEN) {
          if (this.token) {
            // Проверяем, определен ли config.headers
            if (!config.headers) {
              config.headers = {};
            }
            config.headers.OFFICETOKEN = this.token;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    /**
     * Перехватчик полученного ответа от сервера.
     * Завершает сессию в случае получения кода ошибки 401
     */
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (hasIn(error, 'response.status')) {
          if (error.response.status === 401) {
            this.logout();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  //////// Observables

  /**
   * Флаг, что идет процесс аутентификации
   */
  @observable isPending: boolean = false;

  /**
   * Флаг о входе в систему
   */
  @observable isLoggedIn: boolean =
    JSON.parse(`${window.sessionStorage.getItem('isLoggedIn')}`) || false;

  /**
   * Токен безопасности
   */
  @observable token: string | undefined = getCookie('OFFICETOKEN');

  /**
   * Подтверждение на обработку персональных данных
   */
  @observable isConfirmed: boolean =
    JSON.parse(`${window.localStorage.getItem('isConfirmed')}`) || false;

  /**
   * Информация об учетной записи сотрудника УО
   */
  @observable securityAccount?: securityAccount = JSON.parse(
    `${window.localStorage.getItem('securityAccount')}`
  );

  /**
   * Информация о типе организации
   */
  @observable organizationType: OrganizationType = JSON.parse(
    `${window.localStorage.getItem('organizationType')}`
  );

  /**
   * Таймер жизненного цикла токена
   */
  @observable tokenLifecycleTimer: number = 0;

  /**
   * Ссылки на авторизацию в СУДИР
   */
  @observable sudirAuthUrls = JSON.parse(`${window.localStorage.getItem('sudirAuthUrls')}`);

  //////// Computes

  @computed get requiredPermissions(): Array<string> {
    const { roles } = this.securityAccount || {};
    if (roles) {
      //@ts-ignore
      return [...new Set(roles.map((e) => e.code!))];
    }
    return [];
  }

  //////// Actions

  /**
   * Метод меняющий флаг подтверждения на обработку персональных данных
   */
  @action confirmProcessingPersonalData() {
    this.isConfirmed = true;
  }

  /**
   * Метод меняющий флаг процесса аутентификации
   */
  setIsPending(value: boolean) {
    this.isPending = value;
  }

  /**
   * Метод запускающий проверку жизненного цикла токена
   */
  @action startCheckingTokenLifecycle() {
    this.tokenLifecycleTimer = setInterval(() => {
      if (this.token) {
        getTokenTtlUsingPOST({ token: this.token })
          .then((response) => {
            if (
              response.data.code === 4 ||
              response.data.code === 5 ||
              response.data.code === 6 ||
              response.data.code === 7
            ) {
              this.logout();
            } else {
              const tokenExpiration = new Date(response.data.tokenDateExpiration!).getTime();
              const currentDate = new Date(response.data.operationDate!).getTime();
              // если токен старше 20 минут - обновляем.
              const tokenWithout20Minutes = tokenExpiration - 1000 * 60 * 20;
              if (currentDate >= tokenWithout20Minutes) {
                refreshTokenUsingPOST({ token: this.token || '' });
              }
            }
          })
          .catch(() => {
            this.stopCheckingTokenLifecycle();
          });
      }
    }, 1000 * 60 * 10);
  }

  /**
   * Метод останвливающий проверку жизненного цикла токена
   */
  @action stopCheckingTokenLifecycle() {
    clearInterval(this.tokenLifecycleTimer);
  }

  /**
   * Метод по получение ссылок на авторизацию в СУДИР
   */
  @action getSudirAuthUrls() {
    return getCommonConfigurationUsingPOST().then((response) => {
      if (response.data.code === 1) {
        const { sudirAuthenticationUrl, sudirLogoutUrl } = response.data;
        this.sudirAuthUrls = { sudirAuthenticationUrl, sudirLogoutUrl };
      } else {
        throw response.data;
      }
    });
  }

  /**
   * Метод авторизации
   * @param authParams
   * @param isDebug
   */
  @action login(authParams: authenticateParameter, isDebug?: boolean) {
    this.setIsPending(true);
    return (!isDebug ? authenticateUsingPOST(authParams) : authenticateUsingPOSTDebug(authParams))
      .then((response) => {
        if (response.data.code === 1 && response.data.token) {
          runInAction(() => {
            this.token = response.data.token!;
            this.isConfirmed = response.data.securityAccount?.employee?.confirmed!;
            this.securityAccount = response.data.securityAccount;
            this.organizationType =
              response.data?.securityAccount?.employee?.organizationType || Organizations.UO;
          });

          const promises = [
            this.organizationType === Organizations.UO &&
              this.rootStore.addressStore.getHouses(
                response.data.securityAccount?.employee?.id || 0
              ),
            this.organizationType === Organizations.UO &&
              this.rootStore.organizationSettingsStore.getOrganizationSettings(),
            this.organizationType === Organizations.UO &&
              this.rootStore.userSettingsStore.getUserSettings(),
          ].filter(Boolean) as Promise<void>[];

          Promise.all(promises)
            .then(() => {
              runInAction(() => {
                this.isLoggedIn = true;
              });
            })
            .catch(() => {
              this.logout();
            })
            .finally(() => {
              this.setIsPending(false);
            });
        } else {
          this.setIsPending(false);
          throw response.data;
        }
      })
      .finally(() => {
        if (!authParams.code) {
          this.sudirAuthUrls = {};
        }
      });
  }

  /**
   * Метод по завершению сессии
   */
  @action logout() {
    if (this.token) {
      logoutUsingPOST({ token: this.token })
        .then((response) => {
          if (response.data.code !== 1) {
            throw response.data.text;
          }
        })
        .finally(() => {
          runInAction(() => {
            this.isConfirmed = false;
            this.isLoggedIn = false;
            this.token = undefined;
            this.securityAccount = {};
            this.rootStore.addressStore.setHouseList([]);
            this.rootStore.organizationSettingsStore.setOrganizationSettings({});
            this.rootStore.userSettingsStore.setUserSettings({});
          });

          this.stopCheckingTokenLifecycle();

          if (this.sudirAuthUrls?.sudirLogoutUrl) {
            window.location.href = this.sudirAuthUrls.sudirLogoutUrl!;
          }
        });
    }
  }
}
