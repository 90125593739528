import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/Logo.svg';
import { useSendMetricByUser } from 'hooks/useSendMetric';
import { AppBar, Toolbar, UiFlex, UiTypography } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';
import { Organizations } from 'types/publicController';

import { AddressFilter } from './AddressFilter';
import { ProfileMenu } from './ProfileMenu';
import { UserSettings } from './UserSettings';

export const Header = observer(() => {
  const { authStore } = useContext(rootStoreContext);
  const { isLoggedIn, securityAccount, organizationType } = authStore;
  const isUO = organizationType === Organizations.UO;
  const isFKR = organizationType === Organizations.FKR;
  const shortName = securityAccount?.employee?.organization?.shortName;

  // отправка метрики
  useSendMetricByUser({ [organizationType]: shortName || 'УО' });

  return (
    <>
      {isLoggedIn && (
        <AppBar>
          <Toolbar>
            <UiFlex justifyContent="space-between" width="100%">
              <UiFlex alignItems="center">
                <Link to="/">
                  <Logo />
                </Link>
                <UiTypography variant="body2" color="custom.grayDark" ml="32px">
                  {shortName}
                </UiTypography>
              </UiFlex>
              <UiFlex>
                {(isUO || isFKR) && <UserSettings />}
                {isUO && <AddressFilter />}
                <ProfileMenu />
              </UiFlex>
            </UiFlex>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
});
