import format from 'date-fns/format';
import hasIn from 'lodash/hasIn';
import set from 'lodash/set';
import { action, observable, runInAction } from 'mobx';

import {
  getTreatmentsUsingPOST,
  modifyTreatmentResponseUsingPOST,
} from '../api/PrivateOperationController';
import {
  getTreatmentsParameter,
  modifyTreatmentResponseParameter,
  treatment,
  // eslint-disable-next-line
  treatmentResponse,
} from '../types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class TreatmentsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.reset();
  }

  //////// Observables

  /**
   * Список обращений
   */
  @observable treatmentsList: Array<treatment> = [];

  /**
   * Общее количество всех обращений
   */
  @observable treatmentsTotalCount: number = 0;

  /**
   * Выбранное обращение
   */
  @observable selectedTreatment: treatment = {
    treatmentResponses: [],
  };

  /**
   * Черновик ответа на обращение
   */
  @observable treatmentResponseDraft: modifyTreatmentResponseParameter = {};

  //////// Actions

  /**
   * Метод получающий список обращений
   * @param searchParams
   */
  @action getTreatments(searchParams: getTreatmentsParameter) {
    getTreatmentsUsingPOST(searchParams).then((response) => {
      if (response.data.code === 1) {
        runInAction(() => {
          this.treatmentsTotalCount = response.data.totalCount!;
          this.treatmentsList = response.data.treatments!;
        });
      } else {
        throw response.data.text;
      }
    });
  }

  /**
   * Метод получения обращения по id
   * @param id
   */
  @action getTreatment(id: number) {
    getTreatmentsUsingPOST({ ids: [id] }).then((response) => {
      if (response.data.code === 1) {
        this.setSelectedTreatment(response.data.treatments![0]);

        response.data.treatments![0]?.treatmentResponses?.forEach((treatmentResponse) => {
          if (treatmentResponse.status === 'DRAFT') {
            this.setTreatmentResponseDraft('UPDATE', treatmentResponse);
          }
        });
      }
    });
  }

  /**
   * Метод записывающий выбранное обращение
   * @param value
   */
  @action setSelectedTreatment(value: treatment) {
    this.selectedTreatment = value;
  }

  /**
   * Метод записывающий ответ на обращение
   * @param modificationType
   * @param treatmentResponse
   */
  @action setTreatmentResponseDraft(
    modificationType: string,
    treatmentResponse: treatmentResponse
  ) {
    this.treatmentResponseDraft = {
      modificationType,
      treatmentResponse,
    };
  }

  /**
   * Метод записывающий ответ на обращение по "ключ/значение"
   * @param key
   * @param value
   */
  @action setTreatmentResponseDraftByKey(key: string, value: any) {
    set(this.treatmentResponseDraft, key, value);
  }

  /**
   * Метод по отправке/сохранению ответа на обращение
   * @param id
   * @param status
   */
  @action modifyTreatmentResponse(id: number, status?: string) {
    const { modificationType, treatmentResponse } = this.treatmentResponseDraft;
    const date = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    const modifyParams: treatmentResponse = { treatment: { id }, status };

    if (modificationType === 'CREATE') {
      modifyParams.createDate = date;
    } else if (modificationType === 'UPDATE') {
      modifyParams.changeDate = date;
    }

    return modifyTreatmentResponseUsingPOST({
      modificationType,
      treatmentResponse: { ...treatmentResponse, ...modifyParams },
    }).then((response) => {
      if (response.data.code === 1) {
        this.rootStore.commonStore.setNotification({
          text: `Обращение успешно сохранено!`,
          type: 'success',
        });
        this.getTreatment(id!);
      }
    });
  }

  /**
   * Метод сброса значений черновика ответа на обращение в начальное состояние
   */
  @action reset() {
    this.treatmentResponseDraft = {
      modificationType: 'CREATE',
      treatmentResponse: {
        firstName: hasIn(this.rootStore.authStore.securityAccount, 'employee.firstName')
          ? this.rootStore.authStore.securityAccount!.employee!.firstName
          : '',
        middleName: hasIn(this.rootStore.authStore.securityAccount, 'employee.middleName')
          ? this.rootStore.authStore.securityAccount!.employee!.middleName
          : '',
        lastName: hasIn(this.rootStore.authStore.securityAccount, 'employee.lastName')
          ? this.rootStore.authStore.securityAccount!.employee!.lastName
          : '',
        treatment: {},
        content: '',
        attachments: [],
        status: '',
      },
    };
  }
}
