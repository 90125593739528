import React from 'react';
import { useHistory } from 'react-router-dom';

import { ChevronLeftIcon, IconButton } from 'shared/icons';
import { Box, Typography } from 'shared/ui';

export interface PageHeadingProps {
  text: string;
  withGoBackLink?: boolean;
  backLink?: string;
  mb?: number;
}

export const PageHeading = ({ text, withGoBackLink, backLink, mb }: PageHeadingProps) => {
  const history = useHistory();
  return (
    <Box display="flex" alignItems="center" mb={mb ?? 2}>
      {withGoBackLink ? (
        <Box mr={2}>
          <IconButton
            aria-label="back"
            onClick={() => (backLink ? history.push(backLink) : history.goBack())}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      ) : null}
      <Typography variant="h1">{text}</Typography>
    </Box>
  );
};
