import set from 'lodash/set';
import { action, computed, observable, runInAction } from 'mobx';

import {
  getAppointmentsUsingPOST,
  getSecurityRolesUsingPOST,
  getUserUsingPOST,
  modifyUserUsingPOST,
} from '../api/PrivateOperationController';
import {
  appointment,
  employee,
  house,
  securityAccount,
  securityRole,
} from '../types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class UsersStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  //////// Observables

  /**
   * Тип операции
   */
  @observable modificationType: 'CREATE' | 'UPDATE' | 'DELETE' = 'CREATE';

  /**
   * Информация о сотруднике УО
   */
  @observable employee: employee = {
    lastName: undefined,
    firstName: undefined,
    middleName: undefined,
    appointment: undefined,
    phoneNumber: undefined,
    email: undefined,
  };

  /**
   * Информация об учетной записи сотрудника УО
   */
  @observable securityAccount: securityAccount = {
    login: undefined,
    password: undefined,
    roles: undefined,
    isLocked: undefined,
    lockReason: undefined,
  };

  /**
   * Список списка должностей УО
   */
  @observable appointmentsList: Array<appointment> = [];

  /**
   * Список ролей
   */
  @observable securityRolesList: Array<securityRole> = [];

  /**
   * Список выбранных домов
   */
  @observable selectedHousesList: Array<house> = [];

  /**
   * Флаг, что сотрудник отвечает за все дома в УО
   */
  @observable fullResponsibility: boolean = false;

  //////// Computes

  /**
   * Метод возвращает массив выбранных домов
   */
  @computed get houseIds() {
    return this.selectedHousesList.map((el) => el.id!);
  }

  //////// Actions

  /**
   * Метод записывающий тип операции
   * @param value
   */
  @action setModificationType(value: 'CREATE' | 'UPDATE' | 'DELETE') {
    this.modificationType = value;
  }

  /**
   * Метод записывающий информацию о сотруднике УО по имени ключа
   * @param key
   * @param value
   */
  @action setEmployeeByKey(key: string, value: string | appointment | null) {
    set(this.employee, key, value);
  }

  /**
   * Метод записывающий информацию об учетной записи сотрудника УО по имени ключа
   * @param key
   * @param value
   */
  @action setSecurityAccountByKey(key: string, value: any) {
    set(this.securityAccount, key, value);
  }

  /**
   * Метод записывающий список выбранных домов
   * @param value
   */
  @action setSelectedHouseList(value: Array<house>) {
    this.selectedHousesList = value;
  }

  /**
   *  Метод записывающий флаг, что сотрудник отвечает за все дома в УО
   */
  @action setFullResponsibility(value: boolean) {
    this.fullResponsibility = value;
  }

  /**
   * Метод по получению списка должностей УО
   */
  @action getAppointments() {
    getAppointmentsUsingPOST({}).then((response) => {
      if (response.data.code === 1) {
        if (response.data.items) {
          runInAction(() => {
            this.appointmentsList = response.data.items!;
          });
        }
      }
    });
  }

  /**
   * Метод по получению списка ролей
   */
  @action getSecurityRoles() {
    getSecurityRolesUsingPOST({}).then((response) => {
      if (response.data.code === 1) {
        if (response.data.securityRoles) {
          runInAction(() => {
            this.securityRolesList = response.data.securityRoles!;
          });
        }
      }
    });
  }

  /**
   * Метод по созданию/изменению сотрудника, учетной записи и списка домов сотрудника
   */
  @action modifyUser() {
    const { modificationType, employee, securityAccount, houseIds, fullResponsibility } = this;
    return modifyUserUsingPOST({
      modificationType,
      employee,
      securityAccount,
      houseIds,
      fullResponsibility,
    }).then((response) => {
      if (response.data.code === 1) {
        return true;
      }
    });
  }

  /**
   * Метод по получению информации о сотруднике
   * @param employeeId
   */
  @action getUser(employeeId: number) {
    return getUserUsingPOST({ employeeId }).then((response) => {
      if (response.data.code === 1) {
        runInAction(() => {
          this.employee = response.data.employee!;
          this.securityAccount = response.data.securityAccounts![0];
          this.fullResponsibility = response.data.fullResponsibility!;
          this.selectedHousesList = response.data.houses!;
        });
      }
      return response.data;
    });
  }

  /***
   * Метод сброса значений наблюдаемых полей в начальное состояние
   */
  @action reset() {
    this.modificationType = 'CREATE';
    this.employee = {
      lastName: undefined,
      firstName: undefined,
      middleName: undefined,
      appointment: undefined,
      phoneNumber: undefined,
      email: undefined,
    };
    this.securityAccount = {
      login: undefined,
      password: undefined,
      roles: undefined,
      isLocked: undefined,
      lockReason: undefined,
    };
    this.appointmentsList = [];
    this.securityRolesList = [];
    this.selectedHousesList = [];
    this.fullResponsibility = false;
  }
}
