import { CancelTokenSource } from 'axios';
import React, { ChangeEvent, FC, useCallback, useContext, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  geNotificationsTsAddress,
  geNotificationsTsAddressAll,
} from 'api/PrivateOperationController';
import { SearchIcon } from 'shared/icons';
import { TextField, Grid, InputAdornment, UiFlex, UiTypography } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';

import { AddressFinderCount, AddressFinderList } from './components';
import { useLifecicle } from './lifecicle';

export const AddressFinder: FC = () => {
  const [filter, setFilter] = useState<string | undefined>();
  const [searchInputVal, setSearchInputVal] = useState<string | undefined>();

  const [debouncedTextSearch] = useDebouncedCallback((value: string) => {
    if (!value || value?.length >= 3) {
      setFilter(value);
    }
  }, 500);

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInputVal(e.target.value);
    debouncedTextSearch(e.target.value);
  };

  // const { status, data, error } = useQuery(['todos', filter], () =>
  //   geNotificationsTsAddress({
  //     organizationId: securityAccount?.employee?.organization?.id,
  //     pattern: 'Алтуфьевское',
  //     pageFrom: 0,
  //     pageTo: 10,
  //   })
  // );

  const loaderList = useCallback(
    (pageSize: number, pageNumber: number, cancelSource: CancelTokenSource) => {
      if (filter) {
        return geNotificationsTsAddress(
          {
            pattern: filter,
            pageNumber: pageNumber,
            pageSize: pageSize,
          },
          cancelSource.token
        ).then((res) => res.data.items);
      }
      return geNotificationsTsAddressAll(
        {
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
        cancelSource.token
      ).then((res) => res.data.items);
    },
    [filter]
  );

  const listKit = useLifecicle(loaderList);

  return (
    <Grid container justify="space-between" style={{ width: 'auto' }}>
      <UiFlex flexDirection="column">
        <UiTypography color="custom.grayDark" variant="body1" mb={1}>
          Выберите адрес
        </UiTypography>
        <TextField
          style={{ width: 400 }}
          placeholder="Адрес или UNOM"
          variant="outlined"
          size="small"
          value={searchInputVal || ''}
          onChange={handleSearchTextChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <AddressFinderList {...listKit} search={searchInputVal} />
        <AddressFinderCount />
      </UiFlex>
    </Grid>
  );
};
