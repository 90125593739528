import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useState } from 'react';

import { RoomIcon, IconButton } from 'shared/icons';
import {
  Autocomplete,
  TextField,
  Badge,
  Button,
  Chip,
  Tooltip,
  UiTypography,
  UiBox,
  TooltipIcon,
  SidePanel,
} from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';
import { house } from 'types/PrivateOperationController';

export const AddressFilter = observer(() => {
  const { addressStore, userSettingsStore } = useContext(rootStoreContext);
  const { houseList } = addressStore;
  const { userSettings } = userSettingsStore;
  const { bindUnom } = userSettings;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUnoms, setSelectedUnoms] = useState<Array<number>>(bindUnom!);

  const toggleOpenClick = () => {
    setIsOpen(!isOpen);
  };

  const handleAddressesSelect = (e: ChangeEvent<{}>, value: any | null) => {
    setSelectedUnoms(value.map((x: house) => x.unom));
  };

  const handleSaveAddressesClick = () => {
    userSettingsStore
      .modifyUserSettings({ userSetting: { bindUnom: selectedUnoms }, types: ['BIND_UNOM'] })
      .then((response) => {
        if (response.data.code === 1) {
          userSettingsStore.setUserSettingsValueByKey('bindUnom', selectedUnoms);
          toggleOpenClick();
        }
      });
  };

  const renderTooltipIcon = () => (
    <TooltipIcon
      disabled={!bindUnom?.length}
      mt={0}
      tipText={
        <UiBox overflow="auto" maxHeight="250px">
          <UiTypography fontWeight="bold" variant="body2" mb={1}>
            Данные отображаются для адресов:
          </UiTypography>
          {houseList
            .filter((element) => bindUnom!.includes(element!.unom!))
            .map((item) => (
              <UiTypography variant="body2" mb={1 / 2} key={item.id}>
                {item.name}
              </UiTypography>
            ))}
        </UiBox>
      }
      icon={
        <IconButton onClick={toggleOpenClick}>
          <Badge badgeContent={bindUnom && bindUnom.length} color="secondary">
            <RoomIcon />
          </Badge>
        </IconButton>
      }
    />
  );

  return (
    <>
      {renderTooltipIcon()}
      <SidePanel
        onBtnClose={toggleOpenClick}
        onClose={toggleOpenClick}
        anchor="right"
        title="Выбор адреса"
        open={isOpen}>
        <Autocomplete
          options={toJS(houseList)}
          getOptionLabel={(option) => option.address?.name || ''}
          getOptionSelected={(option, value) => value.unom === option.unom}
          multiple
          size="small"
          fullWidth
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Tooltip
                title={option.address?.name || ''}
                key={option.address?.name || ''}
                arrow
                placement={'top'}>
                <Chip label={option.address?.name} {...getTagProps({ index })} />
              </Tooltip>
            ))
          }
          open
          value={toJS(houseList.filter((element) => selectedUnoms!.includes(element!.unom!)))}
          noOptionsText="Нет результатов"
          onChange={handleAddressesSelect}
          disablePortal
          renderInput={(params) => (
            <TextField {...params} label="Адрес" variant="outlined" placeholder="Выбрать" />
          )}
        />
        <Button
          style={{ marginTop: 36 }}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSaveAddressesClick}>
          Сохранить
        </Button>
      </SidePanel>
    </>
  );
});
