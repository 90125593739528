import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { action, observable, autorun, reaction } from 'mobx';

import {
  getUserSettingUsingPOST,
  modifyUserSettingUsingPOST,
} from 'api/PrivateOperationController';
import { WidgetControl, Widgets } from 'types/custom';
import { modifyUserSettingParameter, userSetting } from 'types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class UserSettingsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    /**
     * Реакция. Запись в localStorage значения organizationSettings
     */
    autorun(() => {
      if (!isEmpty(this.userSettings)) {
        const ui = JSON.parse(JSON.stringify(this.userSettings.ui));
        if (!this.userSettings.ui) {
          this.setUserSettingsValueByKey('ui', JSON.stringify(this.defaultWidgetSettings));
        }
        window.localStorage.setItem('userSettings', JSON.stringify(this.userSettings));
      } else {
        window.localStorage.removeItem('userSettings');
      }
    });

    /**
     * Реакция. Запись в localStorage значения sideMenuIsCollapsed
     */
    reaction(
      () => this.sideMenuIsCollapsed,
      (sideMenuIsOpen: boolean) => {
        window.localStorage.setItem('sideMenuIsCollapsed', JSON.stringify(sideMenuIsOpen));
      }
    );
  }

  /**
   * Настройка по умолчанию отображения виджетов рабочего стола
   * @private
   */
  private defaultWidgetSettings: WidgetControl[] = [
    {
      name: 'news',
      title: 'Новости',
      enabled: true,
    },
    {
      name: 'treatments',
      title: 'Обращения жителей',
      enabled: true,
    },
    {
      name: 'soesg',
      title: 'Заявки “Наш город”',
      enabled: true,
    },
  ];

  /**
   * Список прав для отоброжения виджетов
   */
  public widgetPermissions: Record<keyof Widgets, Array<string>> = {
    news: ['UK_NEWS_READ'],
    treatments: ['UK_TREATMENTS_READ', 'FKR_TREATMENTS_READ'],
    soesg: ['UK_ISSUES_READ'],
  };

  //////// Observables

  /**
   * Настройки пользователя
   */
  @observable userSettings: userSetting = JSON.parse(
    `${window.localStorage.getItem('userSettings')}`
  ) || {
    bindUnom: [],
    ui: '',
  };

  /**
   * Отображение бокового меню
   */
  @observable sideMenuIsCollapsed = JSON.parse(
    `${window.localStorage.getItem('sideMenuIsCollapsed')}`
  );

  /**
   * Дата на момент авторизации
   */
  @observable currentDate = new Date();

  //////// Actions

  /**
   * Метод записывающий настройки пользователя
   * value
   */
  @action setUserSettings(value: userSetting) {
    this.userSettings = value;
  }

  /**
   * Метод записывающий настройки пользователя по "ключ/значение"
   * @param key
   * @param value
   */
  @action setUserSettingsValueByKey(key: string, value: Array<number> | string) {
    set(this.userSettings, key, value);
  }

  /**
   * Метод изменяющий отображение бокового меню
   */
  @action toggleSideMenuIsOpen() {
    this.sideMenuIsCollapsed = !this.sideMenuIsCollapsed;
  }
  /**
   * Метод по получению настроек пользователя
   */
  @action getUserSettings() {
    return getUserSettingUsingPOST({}).then((response) => {
      if (response.data.code === 1) {
        this.setUserSettings(response.data.userSetting!);
      }
    });
  }

  /**
   * Метод по сохранению настроек пользователя
   * @param modifyUserSettingParameter
   */
  @action modifyUserSettings(modifyUserSettingParameter: modifyUserSettingParameter) {
    return modifyUserSettingUsingPOST(modifyUserSettingParameter);
  }
}
