import { FilledInputProps } from '@material-ui/core';
import { spacing, SpacingProps } from '@material-ui/system';
import { TextField } from 'mui-rff';
import React from 'react';
import styled from 'styled-components';

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  multiline?: boolean;
  inputProps?: Partial<FilledInputProps>;
  style?: Object;
  disabled?: boolean;
  helperText?: string;
  rows?: number;
  size?: 'small' | 'medium';
  fieldProps?: Object;
  type?:
    | 'password'
    | 'text'
    | 'email'
    | 'number'
    | 'url'
    | 'tel'
    | 'date'
    | 'datetime-local'
    | 'month'
    | 'time'
    | 'week';
} & SpacingProps;

const StyledInput = styled(TextField)<Props>`
  ${spacing}
`;

export const Input = ({
  label,
  name,
  multiline,
  style,
  required = false,
  disabled,
  helperText,
  type,
  inputProps,
  fieldProps,
  mb = 3,
  size = 'small',
  ...other
}: Props) => (
  <StyledInput
    {...other}
    mb={mb}
    helperText={helperText}
    fullWidth
    multiline={multiline}
    label={label}
    variant="outlined"
    size={size}
    name={name}
    style={style}
    required={required}
    InputProps={inputProps}
    fieldProps={fieldProps}
    disabled={disabled}
    type={type}
  />
);
