import { QueryClient } from '@tanstack/react-query';

// Время актуальности данных в кеше (5 мин.)
const STALE_TIME = 300000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      retry: () => false,
    },
  },
});
