import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { TextField } from 'mui-rff';
import React, { useState } from 'react';

import { InputAdornment } from 'shared/ui';

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  multiline?: boolean;
  style?: Object;
  disabled?: boolean;
};

export const PasswordInput = (props: Props) => {
  const {
    label,
    name,
    multiline,
    style = { marginBottom: '24px' },
    required = false,
    disabled,
    ...other
  } = props;
  const [passwordIsMasked, setPasswordIsMasked] = useState<boolean>(true);
  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  return (
    <TextField
      {...other}
      fullWidth
      label={label}
      variant="outlined"
      type={passwordIsMasked ? 'password' : 'text'}
      size="small"
      name={name}
      style={style}
      required={required}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {passwordIsMasked ? (
              <VisibilityIcon
                style={{ cursor: 'pointer', color: '#ABB5BA' }}
                onClick={togglePasswordMask}
              />
            ) : (
              <VisibilityOffIcon
                style={{ cursor: 'pointer', color: '#ABB5BA' }}
                onClick={togglePasswordMask}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
