import { Reducer } from 'react';

import type { TListState, TReducerActions } from '../types';

export const loaderStep = 50;

export enum ReducerActions {
  init = 'INIT',
  loading = 'LOADING',
  setList = 'SET_LIST',
}

type TActionPayload = {
  [ReducerActions.init]: undefined;
  [ReducerActions.loading]: undefined;
  [ReducerActions.setList]: {
    list: unknown[];
    // take: number
    // offset: number
  };
};

export const initialState: TListState = { stopedLoader: false, loading: true };

export const reducer: Reducer<TListState | undefined, TReducerActions<TActionPayload>> = (
  state,
  action
) => {
  switch (action.type) {
    case ReducerActions.init: {
      return { ...initialState };
    }
    case ReducerActions.loading: {
      if (!state) {
        return state;
      }
      return { ...state, loading: true };
    }

    case ReducerActions.setList: {
      if (!state) {
        return state;
      }

      return {
        ...state,
        loading: false,
        list: [...(state.list || []), ...action.payload.list],
        stopedLoader:
          !action.payload.list?.length || action.payload.list?.length < loaderStep ? true : false,
      };
    }

    default:
      return state;
  }
};
