import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import {
  attachment,
  operationResult,
  getAppointmentsResult,
  getAppointmentsParameter,
  getAsuprObjectsResult,
  getAsuprObjectsParameter,
  getAttachmentsResult,
  getAttachmentsParameter,
  getEmployeesResult,
  getEmployeesParameter,
  getGisrdFileResult,
  getGisrdFileParameter,
  getHousesResult,
  getHousesUnomResult,
  getHousesParameter,
  getHousesUnomParameter,
  getIssueHistoricStatisticsResult,
  getIssueHistoricStatisticsParameter,
  getIssueStatisticsResult,
  getIssueStatisticsParameter,
  getNewsResult,
  getNewsParameter,
  getOrganizationSettingResult,
  getOrganizationSettingParameter,
  getOssBallotsResult,
  getOssBallotsParameter,
  getOssDocumentsResult,
  getOssDocumentsParameter,
  getOssProtocolFileContentResult,
  getOssProtocolFileContentParameter,
  getOssProtocolsResult,
  getOssProtocolsParameter,
  getOssProtocolsReportResult,
  getOssProtocolsReportParameter,
  getPollBallotResult,
  getPollBallotParameter,
  getPollDetailsResult,
  getPollDetailsParameter,
  getPollFormListResult,
  getPollFormListParameter,
  getOrganizationsResult,
  getPollHouseInformationParameter,
  getPollMessageResult,
  getPollMessageParameter,
  getPollsResult,
  getPollsParameter,
  getPollsReportResult,
  getPollsReportParameter,
  getSecurityAccountsResult,
  getSecurityAccountsParameter,
  getSecurityRolesResult,
  getSecurityRolesParameter,
  getSoesgIssueDetailsResult,
  getSoesgIssueDetailsParameter,
  getSoesgIssuesResult,
  getSoesgIssuesParameter,
  getSoesgIssuesReportResult,
  getSoesgIssuesReportParameter,
  getTreatmentHistoricStatisticsResult,
  getTreatmentHistoricStatisticsParameter,
  getTreatmentStatisticsResult,
  getTreatmentStatisticsParameter,
  getTreatmentsResult,
  getTreatmentsParameter,
  getTreatmentsReportResult,
  getTreatmentsReportParameter,
  getUserResult,
  getUserParameter,
  getUserSettingResult,
  getUserSettingParameter,
  isLoginExistsResult,
  isLoginExistsParameter,
  modifyAppointmentResult,
  modifyAppointmentParameter,
  modifyAttachmentResult,
  modifyAttachmentParameter,
  modifyEmployeeResult,
  modifyEmployeeParameter,
  modifyEmployeeResponsibilitiesResult,
  modifyEmployeeResponsibilitiesParameter,
  modifyNewsResult,
  modifyNewsParameter,
  modifyOrganizationSettingResult,
  modifyOrganizationSettingParameter,
  modifyOssBallotResult,
  modifyOssBallotParameter,
  modifyPollResult,
  modifyPollParameter,
  modifySecurityAccountResult,
  modifySecurityAccountParameter,
  modifyTreatmentResponseResult,
  modifyTreatmentResponseParameter,
  modifyUserResult,
  modifyUserParameter,
  modifyUserSettingResult,
  modifyUserSettingParameter,
  sendOssDocumentsResult,
  sendOssDocumentsParameter,
  getPollResultsParams,
  getPollResultsReturn,
  getOssCreation,
  getOssCreationResult,
  sendOssBulletParams,
  getOssListResultResult,
  getOssListParameter,
  sendOssBulletReturn,
  sendOssBulletinFileResult,
  getDownloadMessageLinkResult,
  FileType,
  sendOssCreation,
  getOssMessageResult,
  getPollFormListRequestResult,
  getNotificationsTsParams,
  NotificationsTsAddresses,
  sendNotificationAddressParams,
  generalGetParameter,
  getTreatmentsGetParameter,
  treatmentMessageRelationV2,
  getGeneralRequestData,
  treatmentDataV2,
  TreatmentsFilters,
  treatmentV2,
  treatmentMessageV2,
  sendUploadNewsPictureResult,
  OSSRegistryList,
  getOSSRegistryReportResult,
  getOwnerActivityParameter,
  getOwnerActivityReportResult,
  getOwnerListResult,
  getOssBallotUsingPOSTResult,
  OssAndPollCardType,
  getOssOrPollCard,
  getOwnershipsGETResponse,
  isVoteExistParams,
  getIsVoteExistResponse,
  ownershipsOssParams,
  getOSSListFKRParameter,
  GetTreatmentRegistryFKRChart,
  GetAddressByQuery,
  GetAddressByQueryResult,
  getOSSListMZHIParameter,
  GetDistrictsMZHIResult,
} from '../types/PrivateOperationController';

import { getParamsToPost } from './getParamsToPost';
import { paramsSerializerArrToSameList } from './paramsSerializer';

export async function confirmUsingGET(): Promise<AxiosResponse<operationResult>> {
  return await axios.get('/private/operation/confirm');
}
export async function getAppointmentsUsingPOST(
  parameter: getAppointmentsParameter
): Promise<AxiosResponse<getAppointmentsResult>> {
  return await axios.post('/private/operation/getAppointments', parameter);
}
export async function getAsuprObjectsUsingPOST(
  parameter: getAsuprObjectsParameter
): Promise<AxiosResponse<getAsuprObjectsResult>> {
  return await axios.post('/private/operation/getAsuprObjects', parameter);
}
export async function getAttachmentsUsingPOST(
  parameter: getAttachmentsParameter
): Promise<AxiosResponse<getAttachmentsResult>> {
  return await axios.post('/private/operation/getAttachments', parameter);
}
export async function getEmployeesUsingPOST(
  parameter: getEmployeesParameter
): Promise<AxiosResponse<getEmployeesResult>> {
  return await axios.post('/private/operation/getEmployees', parameter);
}
export async function getGisrdFileUsingPOST(
  parameter: getGisrdFileParameter
): Promise<AxiosResponse<getGisrdFileResult>> {
  return await axios.post('/private/operation/getGisrdFile', parameter);
}
export async function getHousesUsingPOST(
  parameter: getHousesParameter
): Promise<AxiosResponse<getHousesResult>> {
  return await axios.post('/private/operation/getHouses', parameter);
}
export async function getHousesUnomUsingGET(
  parameter: getHousesUnomParameter
): Promise<AxiosResponse<getHousesUnomResult>> {
  return await axios.get(`/private/address-ed/search?isActual=&isMkd=&text=${parameter?.text}`);
}
export async function getIssueHistoricStatisticsUsingPOST(
  parameter: getIssueHistoricStatisticsParameter
): Promise<AxiosResponse<getIssueHistoricStatisticsResult>> {
  return await axios.post('/private/operation/getIssueHistoricStatistics', parameter);
}
export async function getIssueStatisticsUsingPOST(
  parameter: getIssueStatisticsParameter
): Promise<AxiosResponse<getIssueStatisticsResult>> {
  return await axios.post('/private/operation/getIssueStatistics', parameter);
}
export async function getNewsUsingPOST(
  parameter: getNewsParameter
): Promise<AxiosResponse<getNewsResult>> {
  return await axios.post('/private/operation/getNews', parameter);
}
export async function getOrganizationSettingUsingPOST(
  parameter: getOrganizationSettingParameter
): Promise<AxiosResponse<getOrganizationSettingResult>> {
  return await axios.post('/private/operation/getOrganizationSetting', parameter);
}
export async function getOssBallotsUsingPOST(
  parameter: getOssBallotsParameter
): Promise<AxiosResponse<getOssBallotsResult>> {
  return await axios.post('/private/operation/getOssBallots', parameter);
}
export async function getOssDocumentsUsingPOST(
  parameter: getOssDocumentsParameter
): Promise<AxiosResponse<getOssDocumentsResult>> {
  return await axios.post('/private/operation/getOssDocuments', parameter);
}
export async function getOssProtocolFileContentUsingPOST(
  parameter: getOssProtocolFileContentParameter
): Promise<AxiosResponse<getOssProtocolFileContentResult>> {
  return await axios.post('/private/operation/getOssProtocolFileContent', parameter);
}
export async function getOssProtocolsUsingPOST(
  parameter: getOssProtocolsParameter
): Promise<AxiosResponse<getOssProtocolsResult>> {
  return await axios.post('/private/operation/getOssProtocols', parameter);
}
export async function getOssProtocolsReportUsingPOST(
  parameter: getOssProtocolsReportParameter
): Promise<AxiosResponse<getOssProtocolsReportResult>> {
  return await axios.post('/private/operation/getOssProtocolsReport', parameter);
}
export async function getPollBallotUsingPOST(
  parameter: getPollBallotParameter
): Promise<AxiosResponse<getPollBallotResult>> {
  return await axios.post('/private/operation/getPollBallot', parameter);
}
export async function getPollDetailsUsingPOST(
  parameter: getPollDetailsParameter
): Promise<AxiosResponse<getPollDetailsResult>> {
  return await axios.post('/private/operation/getPollDetails', parameter);
}
export async function getPollFormListUsingPOST(
  parameter: getPollFormListParameter
): Promise<AxiosResponse<getPollFormListResult>> {
  return await axios.post('/private/operation/getPollFormList', parameter);
}
export async function getPollFormListUsingNewPOST(
  parameter: getPollFormListParameter
): Promise<AxiosResponse<getPollFormListRequestResult>> {
  return await axios.get('/private/oss-ed/formList', { params: { ...parameter } });
}
export async function getPollHouseInformationUsingPOST(
  parameter: getPollHouseInformationParameter
): Promise<AxiosResponse<getOrganizationsResult>> {
  return await axios.post('/private/operation/getPollHouseInformation', parameter);
}
export async function getPollMessageUsingPOST(
  parameter: getPollMessageParameter
): Promise<AxiosResponse<getPollMessageResult>> {
  return await axios.post('/private/operation/getPollMessage', parameter);
}
export async function getPollsUsingPOST(
  parameter: getPollsParameter
): Promise<AxiosResponse<getPollsResult>> {
  return await axios.post('/private/operation/getPolls', parameter);
}
export async function getPollsReportUsingPOST(
  parameter: getPollsReportParameter
): Promise<AxiosResponse<getPollsReportResult>> {
  return await axios.post('/private/operation/getPollsReport', parameter);
}
export async function getSecurityAccountsUsingPOST(
  parameter: getSecurityAccountsParameter
): Promise<AxiosResponse<getSecurityAccountsResult>> {
  return await axios.post('/private/operation/getSecurityAccounts', parameter);
}
export async function getSecurityRolesUsingPOST(
  parameter: getSecurityRolesParameter
): Promise<AxiosResponse<getSecurityRolesResult>> {
  return await axios.post('/private/operation/getSecurityRoles', parameter);
}
export async function getSoesgIssueDetailsUsingPOST(
  parameter: getSoesgIssueDetailsParameter
): Promise<AxiosResponse<getSoesgIssueDetailsResult>> {
  return await axios.post('/private/operation/getSoesgIssueDetails', parameter);
}
export async function getSoesgIssuesUsingPOST(
  parameter: getSoesgIssuesParameter
): Promise<AxiosResponse<getSoesgIssuesResult>> {
  return await axios.post('/private/operation/getSoesgIssues', parameter);
}
export async function getSoesgIssuesReportUsingPOST(
  parameter: getSoesgIssuesReportParameter
): Promise<AxiosResponse<getSoesgIssuesReportResult>> {
  return await axios.post('/private/operation/getSoesgIssuesReport', parameter);
}
export async function getTreatmentHistoricStatisticsUsingPOST(
  parameter?: getTreatmentHistoricStatisticsParameter
): Promise<AxiosResponse<getTreatmentHistoricStatisticsResult>> {
  return await axios.post('/private/operation/getTreatmentHistoricStatistics', parameter);
}
export async function getTreatmentStatisticsUsingPOST(
  parameter: getTreatmentStatisticsParameter
): Promise<AxiosResponse<getTreatmentStatisticsResult>> {
  return await axios.post('/private/operation/getTreatmentStatistics', parameter);
}
export async function getTreatmentsUsingPOST(
  parameter: getTreatmentsParameter
): Promise<AxiosResponse<getTreatmentsResult>> {
  return await axios.post('/private/operation/getTreatments', parameter);
}

//---------------------------

export async function getTreatmentMessagesUsingGetV2(
  id: number | string
): Promise<AxiosResponse<getGeneralRequestData<treatmentMessageV2[]>>> {
  return axios.get(`/private/operation/treatments/${id}/messages`);
}
export async function getTreatmentUsingGetV2(
  id: number | string
): Promise<AxiosResponse<getGeneralRequestData<treatmentV2>>> {
  return axios.get(`/private/operation/treatments/${id}`);
}
export async function getTreatmentsUsingGetV2(
  parameter: generalGetParameter & getTreatmentsGetParameter
): Promise<AxiosResponse<getGeneralRequestData<treatmentDataV2>>> {
  return await axios.get('/private/operation/treatments', {
    params: { ...parameter },
    paramsSerializer: (params) => paramsSerializerArrToSameList(params),
  });
}
export async function getTreatmentsInfoGET(): Promise<
  AxiosResponse<getGeneralRequestData<TreatmentsFilters>>
> {
  return await axios.get('/private/operation/treatments/info');
}
export async function getTreatmentsReportUsingPOST(
  parameter: getTreatmentsReportParameter
): Promise<AxiosResponse<getTreatmentsReportResult>> {
  return await axios.post('/private/operation/getTreatmentsReport', parameter);
}
export async function getTreatmentsReportUsingGetV2(
  parameter: generalGetParameter & getTreatmentsGetParameter
): Promise<AxiosResponse<getTreatmentsReportResult>> {
  return await axios.get('/private/operation/treatments/report', {
    params: { ...parameter },
    paramsSerializer: (params) => paramsSerializerArrToSameList(params),
  });
}
export async function setTreatmentsMessageUsingPost(
  id: number | string,
  parameter: any
): Promise<AxiosResponse<any>> {
  return await axios.post(
    `/private/operation/treatments/${id}/messages?externalTreatmentId=${id}`,
    parameter,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

//--------------------------------

export async function getUserUsingPOST(
  parameter: getUserParameter
): Promise<AxiosResponse<getUserResult>> {
  return await axios.post('/private/operation/getUser', parameter);
}
export async function getUserSettingUsingPOST(
  parameter: getUserSettingParameter
): Promise<AxiosResponse<getUserSettingResult>> {
  return await axios.post('/private/operation/getUserSetting', parameter);
}
export async function isLoginExistsUsingPOST(
  parameter: isLoginExistsParameter
): Promise<AxiosResponse<isLoginExistsResult>> {
  return await axios.post('/private/operation/isLoginExists', parameter);
}
export async function modifyAppointmentUsingPOST(
  parameter: modifyAppointmentParameter
): Promise<AxiosResponse<modifyAppointmentResult>> {
  return await axios.post('/private/operation/modifyAppointment', parameter);
}
export async function modifyAttachmentUsingPOST(
  parameter: modifyAttachmentParameter
): Promise<AxiosResponse<modifyAttachmentResult>> {
  return await axios.post('/private/operation/modifyAttachment', parameter);
}
export async function modifyEmployeeUsingPOST(
  parameter: modifyEmployeeParameter
): Promise<AxiosResponse<modifyEmployeeResult>> {
  return await axios.post('/private/operation/modifyEmployee', parameter);
}
export async function modifyEmployeeResponsibilitiesUsingPOST(
  parameter: modifyEmployeeResponsibilitiesParameter
): Promise<AxiosResponse<modifyEmployeeResponsibilitiesResult>> {
  return await axios.post('/private/operation/modifyEmployeeResponsibilities', parameter);
}
export async function modifyNewsUsingPOST(
  parameter: modifyNewsParameter
): Promise<AxiosResponse<modifyNewsResult>> {
  return await axios.post('/private/operation/modifyNews', parameter);
}
export async function modifyOrganizationSettingUsingPOST(
  parameter: modifyOrganizationSettingParameter
): Promise<AxiosResponse<modifyOrganizationSettingResult>> {
  return await axios.post('/private/operation/modifyOrganizationSetting', parameter);
}
export async function modifyOssBallotUsingPOST(
  parameter: modifyOssBallotParameter
): Promise<AxiosResponse<modifyOssBallotResult>> {
  return await axios.post('/private/operation/modifyOssBallot', parameter);
}
export async function modifyPollUsingPOST(
  parameter: modifyPollParameter
): Promise<AxiosResponse<modifyPollResult>> {
  return await axios.post('/private/survey', parameter);
}
export async function modifySecurityAccountUsingPOST(
  parameter: modifySecurityAccountParameter
): Promise<AxiosResponse<modifySecurityAccountResult>> {
  return await axios.post('/private/operation/modifySecurityAccount', parameter);
}
export async function modifyTreatmentResponseUsingPOST(
  parameter: modifyTreatmentResponseParameter
): Promise<AxiosResponse<modifyTreatmentResponseResult>> {
  return await axios.post('/private/operation/modifyTreatmentResponse', parameter);
}
export async function modifyUserUsingPOST(
  parameter: modifyUserParameter
): Promise<AxiosResponse<modifyUserResult>> {
  return await axios.post('/private/operation/modifyUser', parameter);
}
export async function modifyUserSettingUsingPOST(
  parameter: modifyUserSettingParameter
): Promise<AxiosResponse<modifyUserSettingResult>> {
  return await axios.post('/private/operation/modifyUserSetting', parameter);
}
export async function sendOssDocumentsUsingPOST(
  parameter: sendOssDocumentsParameter
): Promise<AxiosResponse<sendOssDocumentsResult>> {
  return await axios.post('/private/operation/sendOssDocuments', parameter);
}
export async function getPollResultsUsingPOST(
  parameter: getPollResultsParams
): Promise<AxiosResponse<getPollResultsReturn>> {
  return await axios.post('', parameter);
}
export async function getOssCreationGET(
  parameter: getOssCreation
): Promise<AxiosResponse<getOssCreationResult>> {
  return await axios.get('/private/oss-ed/checkCreation', {
    params: parameter,
  });
}
export async function sendOssCreationPOST(
  parameter: sendOssCreation
): Promise<AxiosResponse<getOssCreationResult>> {
  return await axios.post('/private/oss-ed/create', parameter);
}
export async function sendOssCreationMessagePOST(
  parameter: sendOssCreation
): Promise<AxiosResponse<getOssMessageResult>> {
  return await axios.post('/private/oss-ed/ownerMessage', parameter);
}
export async function getOssInformationGET(
  id?: string,
  type?: OssAndPollCardType
): Promise<AxiosResponse<getOssOrPollCard>> {
  return await axios.get(`/private/oss-ed/${id}`, { params: { type } });
}
export async function sendOssBulletPOST(
  id: string | undefined,
  parameter: sendOssBulletParams
): Promise<AxiosResponse<sendOssBulletReturn>> {
  return await axios.post(`/private/oss-ed/${id}/decision`, parameter);
}
export async function getOssListPOST(
  parameter: getOssListParameter
): Promise<AxiosResponse<getOssListResultResult>> {
  return await axios.post('/private/oss-ed/list', parameter);
}

// FIXME remove undefined
export async function uploadOssBulletinPOST(
  parameter: FileType[],
  claimId?: string
): Promise<AxiosResponse<sendOssBulletinFileResult>> {
  if (!claimId) {
    return await axios.post('/private/oss-ed/uploadFile', parameter);
  }

  // get параметр claimId добавлен по требованию бакенда
  return await axios.post(`/private/oss-ed/uploadFile?claimId=${claimId}`, parameter);
}
export async function getOssResultMessagePOST(
  parameter: FileType[] | null | undefined
): Promise<AxiosResponse<sendOssBulletinFileResult>> {
  return await axios.post('/uo/oss-ed/uploadFile', parameter);
}
export async function getOssMessageUsingPOST(
  id: string | undefined,
  parameter: getPollMessageParameter
): Promise<AxiosResponse<getPollMessageResult>> {
  return await axios.post(`/v3/uo/oss/${id}/downloadMessage`, parameter);
}
export async function getOssBallotUsingPOST(
  id: number
): Promise<AxiosResponse<getOssBallotUsingPOSTResult>> {
  return await axios.get(`/private/oss-ed/${id}/blanks`);
}
export async function getMessageUsingGET(
  id: number
): Promise<AxiosResponse<getDownloadMessageLinkResult>> {
  return await axios.get(`/private/oss-ed/${id}/downloadMessage`);
}
export async function geNotificationsTsAddressAll(
  parameter: getNotificationsTsParams,
  cancelToken?: AxiosRequestConfig['cancelToken']
): Promise<AxiosResponse<{ items: NotificationsTsAddresses[] }>> {
  return await axios.get('/private/address/find/all', { cancelToken, params: parameter });
}
export async function geNotificationsTsAddress(
  parameter: getNotificationsTsParams,
  cancelToken?: AxiosRequestConfig['cancelToken']
): Promise<AxiosResponse<{ items: NotificationsTsAddresses[] }>> {
  return await axios.get('/private/address/find', { cancelToken, params: parameter });
}
export async function sendNotificationAddress(
  parameter: sendNotificationAddressParams
): Promise<AxiosResponse<{}>> {
  return await axios.post('/private/notification/address/send', parameter);
}

export async function uploadNewsPictureUsingPOST(
  parameter: FileType
): Promise<AxiosResponse<sendUploadNewsPictureResult>> {
  return await axios.post('/private/operation/uploadFile', parameter);
}

export async function getOwnershipsGET(
  parameter: ownershipsOssParams
): Promise<AxiosResponse<getOwnershipsGETResponse>> {
  return await axios.get('/private/oss-ed/ownerships', { params: { ...parameter } });
}

// -----------------------------

export async function getOSSRegistryList(parameter: OSSRegistryList): Promise<AxiosResponse<any>> {
  return await axios.post('/private/operation/loadedDecisions/list', parameter);
}

export async function getOSSRegistryReport(
  parameter: OSSRegistryList
): Promise<AxiosResponse<getOSSRegistryReportResult>> {
  return await axios.post('/private/operation/loadedDecisions/report', parameter);
}

export async function getOSSVoteData(parameter: { id: string }): Promise<AxiosResponse<any>> {
  return await axios.post('/private/operation/loadedDecisions/details', parameter);
}

export async function getOwnersActivity(
  id: number,
  parameter: getOwnerActivityParameter
): Promise<AxiosResponse<getOwnerListResult>> {
  return await axios.get(`/private/oss-ed/${id}/votes/layout`, {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

export async function getOwnerActivityReport(
  id: number
): Promise<AxiosResponse<getOwnerActivityReportResult>> {
  return await axios.get(`/private/oss-ed/${id}/votes/layout/excel`);
}

export async function getIsVoteExist(
  id: number,
  parameter: isVoteExistParams
): Promise<AxiosResponse<getIsVoteExistResponse>> {
  return await axios.post(`/private/oss-ed/${id}/votes/exist`, parameter);
}

export async function getOSSListFKR(
  parameter: getOSSListFKRParameter
): Promise<AxiosResponse<getOssListResultResult>> {
  return await axios.get(`/private/oss-fkr/list`, {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

export async function getOSSReportFKR(
  parameter: getOSSListFKRParameter
): Promise<AxiosResponse<getPollsReportResult>> {
  return await axios.get('/private/oss-fkr/list/excel', {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

export async function getOssFKRInformation(id: string): Promise<AxiosResponse<getOssOrPollCard>> {
  return await axios.get(`private/oss-fkr/${id}`);
}

export async function getTreatmentRegistryFKRChart(): Promise<
  AxiosResponse<GetTreatmentRegistryFKRChart>
> {
  return await axios.get(`private/operation/treatments/fkr/statistics`);
}

export async function getCVSTreatmentsReport(): Promise<AxiosResponse<getTreatmentsReportResult>> {
  return await axios.get('/private/operation/treatments/csvReport');
}

export async function getAddressByQuery(
  parameter: GetAddressByQuery
): Promise<AxiosResponse<GetAddressByQueryResult>> {
  return await axios.get(`/private/address-ed/search`, {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

export async function getNewsFKRUsingPOST(
  parameter: getNewsParameter
): Promise<AxiosResponse<getNewsResult>> {
  return await axios.post('private/fkr-news/list', parameter);
}

export async function modifyNewsFKRUsingPOST(
  parameter: modifyNewsParameter
): Promise<AxiosResponse<modifyNewsResult>> {
  return await axios.post('private/fkr-news', parameter);
}

export async function getOssMZHIInformation(id: string): Promise<AxiosResponse<getOssOrPollCard>> {
  return await axios.get(`private/oss-mzhi/${id}`);
}

export async function getOSSListMZHI(
  parameter: getOSSListMZHIParameter
): Promise<AxiosResponse<getOssListResultResult>> {
  return await axios.get(`/private/oss-mzhi/list`, {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

export async function getOSSReportMZHI(
  parameter: getOSSListMZHIParameter
): Promise<AxiosResponse<getPollsReportResult>> {
  return await axios.get('/private/oss-mzhi/list/excel', {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}

export async function getOSSProtocolMZHI(id: number): Promise<AxiosResponse<getPollsReportResult>> {
  return await axios.get(`/private/oss-mzhi/${id}/protocol`);
}

export async function getDistrictsMZHI(): Promise<AxiosResponse<GetDistrictsMZHIResult>> {
  return await axios.get(`/private/address-mzhi/find-districts`);
}

export async function getAddressMZHIByUnom(
  unom: string
): Promise<AxiosResponse<GetAddressByQueryResult>> {
  return await axios.get(`/private/address-mzhi/get-by-unom/${unom}`);
}

export async function getAddressMZHIByText(
  parameter: GetAddressByQuery
): Promise<AxiosResponse<GetAddressByQueryResult>> {
  return await axios.get(`/private/address-mzhi/search`, {
    params: parameter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
}
