import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useMutation } from 'react-query';

import { confirmUsingGET, modifyEmployeeUsingPOST } from 'api/PrivateOperationController';
import { Button, Dialog, UiFlex, UiTypography } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';
import { modifyEmployeeParameter } from 'types/PrivateOperationController';

export const PersonalDataProcessModal = observer(() => {
  const { authStore, userSettingsStore } = useContext(rootStoreContext);
  const {} = userSettingsStore;
  const { isLoggedIn, isConfirmed, securityAccount } = authStore;
  const { employee } = securityAccount || {};

  const [confirmRequest] = useMutation(() => confirmUsingGET(), {
    onSuccess: () => {
      authStore.confirmProcessingPersonalData();
    },
  });

  const handleConfirmButtonClick = () => {
    confirmRequest({});
  };

  return (
    <Dialog open={isLoggedIn && !isConfirmed}>
      <UiTypography variant="h2">Внимание!</UiTypography>
      <UiTypography pt={2}>
        Соглашаясь с настоящими условиями, пользовать выражает согласие и разрешает Департаменту
        информационных технологий города Москвы (далее - Оператор) осуществлять автоматизированную
        обработку своих персональных данных (фамилии, имени, отчества, места работы), включая сбор,
        запись, накопление, хранение, удаление персональных данных , а также передачу персональных
        данных ГКУ НТУ для достижения указанных целей. При обработке персональных данных,
        предоставленных ему пользователем, Оператор обязуется соблюдать требования Федерального
        закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных».
      </UiTypography>
      <UiTypography pt={2}>
        Согласие пользователя на обработку его персональных данных в соответствии с настоящими
        условиями действует до достижения вышеуказанной цели обработки персональных данных или его
        отзыва пользователем в соответствии с частью 2 статьи 9 Федерального закона от 27 июля 2006
        года № 152-ФЗ «О персональных данных».
      </UiTypography>
      <UiFlex justifyContent="flex-end" pt={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleConfirmButtonClick();
          }}>
          Согласен
        </Button>
        <Button
          color="primary"
          onClick={() => {
            authStore.logout();
          }}>
          Не согласен
        </Button>
      </UiFlex>
    </Dialog>
  );
});
