import { styled } from '@material-ui/core';
import React, { FC, useCallback, useMemo } from 'react';

import { CalendarTodayIcon, IconButton } from 'shared/icons';
import {
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  DatePicker,
  UiFlex,
  UiTypography,
} from 'shared/ui';

import { TimeValue } from '../types';
import { checkCurrentDate, getCurrentTimeValue, timeList } from '../utils';

const SelectStyled = styled(Select)({
  width: '100%',
  '& .MuiSelect-root': {
    padding: 10,
  },
});

export const CleaningDate: FC<{
  disabled: boolean;
  cleaningDate?: string;
  setCleaningDate: (date: Date | null) => void;
}> = ({ setCleaningDate, cleaningDate, disabled }) => {
  return (
    <Grid item style={{ width: '100%' }}>
      <UiTypography color="custom.grayDark" variant="body1" mb={1}>
        Дата уборки
      </UiTypography>
      <Grid item>
        <DatePicker
          disabled={disabled}
          style={{ width: '100%' }}
          placeholder="Выберите дату"
          size="small"
          minDate={new Date()}
          inputVariant="outlined"
          value={cleaningDate || null}
          disableToolbar
          variant="inline"
          format="dd.MM.yyyy"
          onChange={setCleaningDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <IconButton size="small">
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

// const TimeButton = styled(Button)(
//   ({ theme }) => ({
//     borderColor: 'rgba(0, 0, 0, 0.23)',
//     width: 140,
//   })
//   // columnFixed
// );

const CleaningTimeValue: FC<{
  range?: number;
  value?: TimeValue['key'];
  label: string;
  action: (value: TimeValue['key']) => void;
  disabled: boolean;
}> = ({ label, action, value, range, disabled }) => {
  const list = useMemo(() => {
    if (typeof range !== 'number') {
      return timeList;
    } else {
      return timeList.filter((el) => el.key > range);
    }
  }, [range]);
  // return (
  //   <TimeButton variant="outlined" style={{ justifyContent: 'space-between' }}>
  //     <UiTypography
  //       color="custom.grayLight"
  //       variant="body1"
  //       style={{ textTransform: 'none', textAlign: 'left' }}>
  //       {label}
  //     </UiTypography>
  //     <ArrowDropDownIcon />
  //   </TimeButton>
  // );

  const callback = useCallback(
    (event) => {
      return action(event.target.value);
    },
    [action]
  );

  return (
    <SelectStyled
      disabled={disabled}
      displayEmpty
      style={{ width: 160 }}
      variant="outlined"
      value={value || ''}
      onChange={callback}
      placeholder="label">
      <MenuItem value="" disabled>
        {label}
      </MenuItem>
      {list.map((item) => (
        <MenuItem key={item.key} value={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </SelectStyled>
  );
};

const useCurrentDay = (date?: string) => {
  return useMemo(() => checkCurrentDate(date), [date]);
};

export const CleaningTime: FC<{
  disabled: boolean;
  cleaningDate?: string;
  date?: string;
  from?: TimeValue['key'];
  to?: TimeValue['key'];
  setCleaningTimeFrom: (value: TimeValue['key']) => void;
  setCleaningTimeTo: (value: TimeValue['key']) => void;
}> = ({ date, to, from, setCleaningTimeFrom, setCleaningTimeTo, disabled }) => {
  const currentDay = useCurrentDay(date);
  const disabledFrom = useMemo(() => disabled || !date, [disabled, !date]);
  const disabledTo = useMemo(() => disabledFrom || !from, [disabledFrom, !from]);

  const timeFromRange = useMemo<number | undefined>(() => {
    if (!currentDay) {
      return undefined;
    }
    return getCurrentTimeValue().key;
  }, [currentDay]);

  const timeToRange = useMemo<number | undefined>(() => {
    if (!from) {
      return undefined;
    }
    return from;
  }, [from]);

  return (
    <Grid item>
      <UiTypography color="custom.grayDark" variant="body1" mb={1}>
        Время уборки
      </UiTypography>
      <UiFlex flexDirection="row" justifyContent="space-between" alignItems="center">
        <CleaningTimeValue
          label="От"
          action={setCleaningTimeFrom}
          value={from}
          range={timeFromRange}
          disabled={disabledFrom}
        />
        <UiTypography color="custom.grayDark" variant="body1">
          —
        </UiTypography>
        <CleaningTimeValue
          label="До"
          action={setCleaningTimeTo}
          value={to}
          range={timeToRange}
          disabled={disabledTo}
        />
      </UiFlex>
    </Grid>
  );
};

const category = [
  { key: 'SNOW', value: 'Уборка снега' },
  { key: 'SNOW_FROM_ROOF', value: 'Уборка снега с крыши' },
  { key: 'REPAIR_WORK', value: 'Ремонтные работы' },
];

export const NotifyCategory: FC<{
  disabled: boolean;
  cleaningType?: string;
  setCleaningType: (value: string) => void;
}> = ({ setCleaningType, cleaningType, disabled }) => {
  const action = useCallback(
    (event) => {
      return setCleaningType(event.target.value);
    },
    [setCleaningType]
  );

  return (
    <Grid item>
      <UiTypography color="custom.grayDark" variant="body1" mb={1}>
        Категория уведомления
      </UiTypography>
      <SelectStyled
        disabled={disabled}
        displayEmpty
        variant="outlined"
        value={cleaningType || ''}
        onChange={action}
        placeholder="Выберите категорию">
        <MenuItem value="" disabled>
          Выберите категорию
        </MenuItem>
        {category.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </SelectStyled>
    </Grid>
  );
};
