import { useState } from 'react';

import { getUserInstructionLink } from '../api/getUserInstructionLink';

export const useUserInstructions = (onError?: () => void) => {
  const [link, setLink] = useState<null | HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onUserInstructionsClick = () => {
    if (link === null) {
      setIsLoading(true);
      getUserInstructionLink()
        .then((res) => {
          const { url } = res.data;
          if (!url) {
            return onError?.();
          }
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.click();
          setLink(link);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      link.click();
    }
  };

  return {
    onUserInstructionsClick,
    isLoading,
  };
};
