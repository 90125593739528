import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';

import { rootStoreContext } from '../../stores/RootStore';

const IdleDetector: FC = observer(() => {
  const { authStore } = useContext(rootStoreContext);
  const { token } = authStore;
  useEffect(() => {
    if (!!token) {
      authStore.startCheckingTokenLifecycle();
    }

    return () => {
      authStore.stopCheckingTokenLifecycle();
    };
  }, [token]);

  return null;
});

export default IdleDetector;
