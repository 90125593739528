import { cloneDeep } from 'lodash';
import React, { useState, FC, useCallback } from 'react';

import { ResponsivePageContainer } from 'components/PageContainer';
import { Grid, UiFlex, UiPaper } from 'shared/ui';
import { NotificationsTsAddresses } from 'types/PrivateOperationController';

import { AddressFinder } from './AddressFinder';
import { AddressSelectedConsumer } from './context';
import { NotifySender } from './NotifySender';
import { TopPanel } from './TopPanel';

const NotificationsTs: FC = ({ children }) => {
  const [selectedList, setSelected] = useState({});

  const selectItem = useCallback(
    (item: NotificationsTsAddresses) => {
      const list = cloneDeep(selectedList);
      if (selectedList[item.unom]) {
        delete list[item.unom];
        setSelected(list);
      } else {
        setSelected({ ...list, [item.unom]: item });
      }
    },
    [selectedList]
  );

  const cleanSelectedList = useCallback(() => {
    setSelected({});
  }, []);

  return (
    <ResponsivePageContainer>
      <UiFlex flexDirection="column" height="100%">
        <TopPanel />
        <Grid
          container
          justify="space-between"
          style={{ padding: 24, flex: 1, marginBottom: 24 }}
          component={UiPaper}>
          <UiFlex flexDirection="row">
            <AddressSelectedConsumer
              callback={selectItem}
              clean={cleanSelectedList}
              selectedList={selectedList}>
              <AddressFinder />
              <NotifySender />
            </AddressSelectedConsumer>
          </UiFlex>
        </Grid>
      </UiFlex>
    </ResponsivePageContainer>
  );
};

export default NotificationsTs;
