import styled from 'styled-components';

import { theme } from 'theme';

const borderColor = theme.palette.grey['100'];

export const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .table {
    overflow: hidden;
    border-spacing: 0;

    .th,
    .td {
      align-content: flex-start;
      display: flex;
    }

    .header {
      .tr .th {
        min-height: 45px;
      }

      .divider {
        min-width: 1px;
        width: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: '';
          width: 1px;
          height: 16px;
          opacity: 0.5;
          background-color: #abb5ba;
          transition: 0.2s;
        }
      }

      .resizer {
        min-width: 10px;
        width: 10px;
        transition: 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .resizer:after {
        content: '';
        width: 1px;
        height: 16px;
        opacity: 0.5;
        background-color: #abb5ba;
        transition: 0.2s;
      }

      .resizer:hover:after {
        content: '';
        width: 4px;
        height: 100%;
        background-color: #2874f8;
        border-radius: 4px;
      }
    }

    .tr {
      .th,
      .td {
        &:first-of-type {
          padding-left: 1rem;
        }

        &:last-of-type {
          padding-right: 1rem;
        }
      }

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .tr:nth-of-type(odd) {
      background-color: #f9fafb;
      .td {
        background-color: #f9fafb;
      }
    }

    .tr:hover {
      .td,
      .customRowContainer {
        background-color: #ecfafc !important;
      }
      cursor: pointer;
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem 1rem;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    .td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }

    &.sticky {
      .header,
      .footer {
        position: sticky;
        z-index: 1;
      }

      .header {
        top: 0;
        box-shadow: 0px 2px 2px ${borderColor};
        font-weight: 600;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -2px 2px ${borderColor};
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 2px ${borderColor};
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 2px ${borderColor};
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;
