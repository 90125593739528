import set from 'lodash/set';
import { action, observable } from 'mobx';

import { modifyNewsUsingPOST } from '../api/PrivateOperationController';
import { modifyNewsParameter, news } from '../types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class NewsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.reset();
  }

  //////// Observables

  /**
   * Редактируемая новость
   */
  @observable newsDraft: modifyNewsParameter = {};

  /**
   * Общее количество всех новостей
   */
  @observable newsTotalCount: number = 0;

  /**
   * Список новостей
   */
  @observable newsList: Array<news> = [];

  /**
   * Поисковая строка фильтров
   */
  @observable searchFilterParams: string = '';

  //////// Actions

  /**
   * Метод записывающий поисковую строку с фильтрами
   * @param searchParams
   */
  @action setSearchFilterParams(searchParams: string) {
    this.searchFilterParams = searchParams;
  }

  /**
   * Метод записывающий редактируемую новость по "ключ/значение"
   * @param key
   * @param value
   */
  @action setNewsDraftValueByKey(key: string, value: any) {
    set(this.newsDraft, key, value);
  }

  /**
   * Метод записывающий редактируемую новость
   * @param value
   */
  @action setNewsDraft(value: modifyNewsParameter) {
    this.newsDraft = value;
  }

  /**
   * Метод для сохранения общего количества новостей
   */
  @action setNewsTotalCount(value: number) {
    this.newsTotalCount = value;
  }

  /**
   * Метод для сохранения списка новостей
   */
  @action setNewsList(list: news[]) {
    this.newsList = list;
  }

  /**
   * Метод по сохранению редактируемой новости
   * deprecated
   */
  @action modifyNews() {
    return modifyNewsUsingPOST(this.newsDraft).then((response) => {
      return response.data.code === 1;
    });
  }

  /**
   * Метод сброса значений наблюдаемых полей в начальное состояние
   */
  @action reset() {
    this.newsDraft = {
      modificationType: 'CREATE',
      news: {
        theme: '',
        createDate: '',
        changeDate: '',
        endPublicDate: undefined,
        status: '',
        destinations: [],
        content: '',
        contentPreview: '',
        type: 'SIMPLE',
        forAllUsers: false,
        withPush: false,
      },
    };
    this.newsList = [];
  }
}
