import React, { FC } from 'react';

import { DialogTitle, DialogContent, DialogActions, Button, Dialog, UiTypography } from 'shared/ui';

export const ModalNotifyTs: FC<{ callback?: () => void; close: () => void }> = ({
  callback,
  close,
}) => {
  return (
    <Dialog onClose={close} open maxWidth="md" scroll="paper">
      <DialogTitle>
        <UiTypography variant="h2" mb="17px">
          Рассылка уведомления
        </UiTypography>
      </DialogTitle>
      <DialogContent>Подтвердите, что вы хотите выполнить рассылку уведомлений</DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus variant="text" onClick={callback}>
          Подтвердить
        </Button>
        <Button color="default" variant="text" onClick={close}>
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
