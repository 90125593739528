import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { ymId } from 'shared/constants';

export const useSendMetricByPath = (targetName: string) => {
  const pathName = useLocation();
  useEffect(() => {
    // @ts-ignore
    window.ym(ymId, 'hit', targetName);
  }, [pathName]);
};

export const useSendMetricByUser = (userType: Record<string, string>) => {
  useEffect(() => {
    // @ts-ignore
    window.ym(ymId, 'userParams', { user_type: userType });
  }, [userType]);
};
