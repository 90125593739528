import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { rootStoreContext } from '../stores/RootStore';

const PublicRoute = observer((props: RouteProps) => {
  const { ...restProps } = props;
  const { authStore } = useContext(rootStoreContext);

  if (!authStore.isLoggedIn) return <Route {...restProps} />;
  return <Redirect to="/" />;
});

export default PublicRoute;
