import { TableSortLabel } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Row, TableInstance } from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';

import { UiFlex, UiTypography, UiPaper, Scrollbar } from 'shared/ui';

import { LoadingOverlay, BodyCentered } from './components';
import { StyleWrapper } from './styles';
import { getCellProps, Pagination } from './TablePaginated';
import { BodyProps } from './types';

type TableProps<D extends object> = {
  data: D[];
  headerHeight?: number;
  table: TableInstance<D>;
  isLoading?: boolean;
  onRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: D) => void;
  Body?: React.ComponentType<BodyProps<D>>;
  children: (bodyProps: BodyProps<D>) => ReactNode;
  enableColumnResizing?: boolean;
  customRow?: (props: {
    row: Row<D>;
    getCellProps: typeof getCellProps;
    onRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: D) => void;
  }) => ReactNode;
  topPanel?: ReactNode;
  pagination?: Pagination<D>;
};

export const Table = <D extends object>(props: TableProps<D>) => {
  const {
    headerHeight = 45,
    children,
    data,
    isLoading,
    table,
    onRowClick,
    enableColumnResizing,
    customRow,
    topPanel,
    pagination,
  } = props;
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow } = table;

  return (
    <StyleWrapper>
      <AutoSizer>
        {({ height, width }) => (
          <UiPaper {...getTableProps()} className="table sticky" style={{ width, height }}>
            <Scrollbar style={{ width, height }}>
              {(data && data.length) || isLoading ? null : (
                <BodyCentered>
                  <UiTypography variant="body">Нет результатов</UiTypography>
                </BodyCentered>
              )}
              <div className="header">
                <div style={{ backgroundColor: '#fff' }}>{topPanel}</div>
                {headerGroups.map((headerGroup) => (
                  <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const isColumnActive = pagination && pagination?.sortType === column.id;
                      return (
                        <div className="th" {...column.getHeaderProps(getCellProps(column))}>
                          <UiFlex
                            flexDirection="column"
                            height="100%"
                            width="100%"
                            {...column.getSortByToggleProps()}
                            title="">
                            <UiFlex alignItems="center" height="100%">
                              <UiTypography
                                as="div"
                                fontSize="0.75rem"
                                color="custom.grayDark"
                                lineHeight="1rem"
                                mr={1}>
                                {column.render('Header')}
                              </UiTypography>

                              {column.id !== 'selection'
                                ? column.canSort && (
                                    <TableSortLabel
                                      active={pagination ? isColumnActive : column.isSorted}
                                      direction={
                                        isColumnActive
                                          ? pagination?.sortDescending
                                            ? 'desc'
                                            : 'asc'
                                          : column.isSortedDesc
                                          ? 'desc'
                                          : 'asc'
                                      }
                                    />
                                  )
                                : null}
                            </UiFlex>
                          </UiFlex>

                          {enableColumnResizing && column.canResize && (
                            <div
                              {...column.getResizerProps()}
                              className="resizer"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                            />
                          )}
                          {enableColumnResizing && !column.canResize && <div className="divider" />}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>

              {isLoading ? (
                <LoadingOverlay enabled={isLoading} />
              ) : (
                <div className="body" {...getTableBodyProps()}>
                  {children({
                    rows: table.rows,
                    prepareRow,
                    onRowClick,
                    width,
                    height: height - headerHeight,
                    customRow,
                  })}
                </div>
              )}
            </Scrollbar>
          </UiPaper>
        )}
      </AutoSizer>
    </StyleWrapper>
  );
};

export const TBody = <D extends object>({
  rows,
  prepareRow,
  onRowClick,
  customRow,
}: BodyProps<D>) => {
  return (
    <>
      {rows.map((row) => {
        prepareRow(row);
        return customRow ? (
          customRow({ row, getCellProps, onRowClick })
        ) : (
          <div
            className="tr"
            onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
              if (onRowClick) {
                onRowClick(event, row.original);
              }
            }}
            {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <UiTypography
                  as="span"
                  className="td"
                  {...cell.getCellProps(getCellProps(cell.column))}>
                  {cell.render('Cell')}
                </UiTypography>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
