import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { spacing, SpacingProps } from '@material-ui/system';
import ruLocale from 'date-fns/locale/ru';
import React from 'react';

type Props = {
  label: string;
  name: string;
  format?: string;
  maxDate?: Date;
  required?: boolean;
  style?: Object;
  minDate?: Date;
  disabled?: boolean;
  openTo?: 'year' | 'date' | 'month' | undefined;
  value: Date;
  onChange: (date: Date) => void;
  helperText?: string;
  error?: boolean;
  fullWidth?: boolean;
} & SpacingProps;

export const DateInput = ({
  label,
  name,
  format = 'dd.MM.yyyy',
  maxDate = new Date(3100, 1, 1),
  required = false,
  style,
  minDate,
  fullWidth,
  disabled,
  mb = 3,
  openTo,
  value,
  onChange,
  ...other
}: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <KeyboardDatePicker
        {...other}
        disableToolbar
        fullWidth={fullWidth}
        variant="dialog"
        inputVariant="outlined"
        margin="normal"
        okLabel="OK"
        cancelLabel="ОТМЕНА"
        label={label}
        value={value}
        size="small"
        style={style}
        // @ts-ignore
        onChange={onChange}
        name={name}
        format={format}
        disabled={disabled}
        minDate={minDate}
        required={required}
        maxDate={maxDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
