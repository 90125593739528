import { spacing, SpacingProps } from '@material-ui/system';
import { ValidationErrors } from 'final-form';
import { Autocomplete as AutocompleteMui } from 'mui-rff';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

type Option = {
  id?: string;
  value?: string;
  label?: string;
};

type Props = {
  options: Option[];
  value?: object;
  onChange?: (e: ChangeEvent<{}>, value: object) => void;
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  style?: Object;
  errors?: ValidationErrors;
  touched?: { [key: string]: boolean } | undefined;
  disableClearable?: boolean;
} & SpacingProps;

export const StyledAutocompleteOnChange = styled(AutocompleteMui)<Props>`
  ${spacing}
`;

export const AutocompleteOnChange = ({
  options = [],
  value,
  onChange,
  label,
  name,
  required,
  style,
  disabled,
  errors,
  touched,
  mb = 3,
  ...other
}: Props) => (
  <StyledAutocompleteOnChange
    disableClearable={required}
    {...other}
    mb={mb}
    options={options}
    value={value}
    onChange={onChange}
    style={style}
    getOptionLabel={(option: Option) => (option.label ? option.label : option.value || '')}
    getOptionSelected={(option: Option, value: Option) =>
      value.id ? value.id === option.id : value.value === option.value || ''
    }
    size="small"
    fullWidth
    autoComplete
    required={required}
    noOptionsText="Нет результатов"
    renderOption={(option: Option) => (option.label ? option.label : option.value)}
    name={name}
    textFieldProps={{ variant: 'outlined' }}
    disabled={disabled}
    label={label}
    helperText={errors && touched && touched[name] ? errors[name] : ''}
  />
);
