import { TablePagination } from '@material-ui/core';
import React, { ReactNode, useEffect } from 'react';
import {
  useFlexLayout,
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  Column,
  IdType,
  useResizeColumns,
  Row,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { UiFlex } from 'shared/ui';

import { PAGE_SIZES } from './const';
import { TablePaginationActions } from './Pagination';
import { Table, TBody } from './Table';

export type Pagination<Row extends object> = {
  pageSize: number;
  pageNumber: number;
  sortDescending?: boolean | null;
  sortType?: IdType<Row> | null;
};

export type TablePaginatedProps<D extends object> = {
  rowCount: number;
  data: D[];
  columns: Array<Column<D>>;
  onPaginationChange?: (p: Pagination<D>) => void;
  isLoading?: boolean;
  pagination: Pagination<D>;
  onRowClick?: (row: D) => void;
  enableColumnResizing?: boolean;
  customRow?: (props: {
    row: Row<D>;
    getCellProps: typeof getCellProps;
    onRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: D) => void;
  }) => ReactNode;
  topPanel?: ReactNode;
};

export const getCellProps = (column: {
  style?: React.CSSProperties;
  collapse?: boolean;
  width?: string | number;
}) => {
  const styleProps = column.style || ({} as React.CSSProperties);
  if (column.collapse && column.width) {
    styleProps.maxWidth = column.width;
  }
  return { style: styleProps };
};

export const TablePaginated = <D extends object>(props: TablePaginatedProps<D>) => {
  const {
    data,
    columns,
    rowCount,
    pagination,
    isLoading,
    onRowClick,
    onPaginationChange,
    enableColumnResizing,
    customRow,
    topPanel,
  } = props;

  const pageCount = Math.ceil(rowCount / pagination.pageSize);

  const table = useTable<D>(
    {
      columns,
      data,
      pageCount,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableMultiSort: true,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      initialState: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageNumber,
        sortBy: pagination.sortType
          ? [{ id: pagination.sortType, desc: Boolean(pagination.sortDescending) }]
          : [],
      },
    },
    useFlexLayout,
    useSticky,
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns
  );

  const {
    state: { pageIndex, pageSize, sortBy },
    gotoPage,
    setPageSize,
  } = table;

  // update pageIndex in table state if pageIndex was changed externally (with resetPage probably)
  useEffect(() => {
    if (pagination.pageNumber !== pageIndex) {
      gotoPage(pagination.pageNumber);
    }
  }, [pagination.pageNumber]);

  useEffect(() => {
    if (onPaginationChange) {
      const sorted = sortBy && sortBy[0];
      onPaginationChange({
        pageSize,
        pageNumber: pageIndex,
        sortDescending: sorted ? sorted.desc : undefined,
        sortType: sorted ? sorted.id : undefined,
      });
    }
  }, [pageIndex, pageSize, sortBy]);

  return (
    <UiFlex flexDirection="column" flexGrow={1} height="100%">
      <Table
        isLoading={isLoading}
        data={data}
        table={table}
        customRow={customRow}
        pagination={pagination}
        enableColumnResizing={enableColumnResizing}
        onRowClick={(e, row) => {
          e.preventDefault();
          onRowClick && onRowClick(row);
        }}
        topPanel={topPanel}>
        {(props) => <TBody {...props} rows={table.page} />}
      </Table>

      <UiFlex height={48} alignItems="center" mt={3} mx={-4} bgcolor="common.white" boxShadow={1}>
        <TablePagination
          rowsPerPageOptions={PAGE_SIZES}
          component="div"
          count={rowCount}
          rowsPerPage={pageSize}
          page={pageIndex}
          onChangePage={(event: unknown, newPage: number) => {
            gotoPage(newPage);
          }}
          onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPageSize(parseInt(event.target.value, 10));
            gotoPage(0);
          }}
          labelRowsPerPage="Отображать по:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
          }
          ActionsComponent={TablePaginationActions}
        />
      </UiFlex>
    </UiFlex>
  );
};
