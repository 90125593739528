import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'mobx-react-lite/batchingForReactDom';
import promiseFinally from 'promise.prototype.finally';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './api/request.config';

promiseFinally.shim();

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) module.hot.accept();
