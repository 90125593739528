import axios, { AxiosResponse } from 'axios';

import {
  authenticateResult,
  authenticateParameter,
  getCommonConfigurationResult,
  getTokenTtlResult,
  getTokenTtlParameter,
  operationResult,
  logoutParameter,
  refreshTokenResult,
  refreshTokenParameter,
} from '../types/publicController';

export async function authenticateUsingPOST(
  parameter: authenticateParameter
): Promise<AxiosResponse<authenticateResult>> {
  return await axios.post('/public/authenticate', parameter);
}
export async function getCommonConfigurationUsingPOST(): Promise<
  AxiosResponse<getCommonConfigurationResult>
> {
  return await axios.post('/public/getCommonConfiguration');
}
export async function getTokenTtlUsingPOST(
  parameter: getTokenTtlParameter
): Promise<AxiosResponse<getTokenTtlResult>> {
  return await axios.post('/public/getTokenTtl', parameter);
}
export async function logoutUsingPOST(
  parameter: logoutParameter
): Promise<AxiosResponse<operationResult>> {
  return await axios.post('/public/logout', parameter);
}
export async function refreshTokenUsingPOST(
  parameter: refreshTokenParameter
): Promise<AxiosResponse<refreshTokenResult>> {
  return await axios.post('/public/refreshToken', parameter);
}
