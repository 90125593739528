import { Button } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  padding: 2rem;
  width: 100%;
  margin: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 2rem;
`;

const Pre = styled.pre`
  white-space: break-spaces;
  padding: 4rem;
  width: 100%;
`;

interface ErrorFallbackProp {
  error: Error;
}

const ErrorFallback = function ErrorFallback({ error }: ErrorFallbackProp) {
  const path = useLocation();
  const pathName = path.pathname;
  axios.post('u/pal', {
    message: error.message,
    name: error.name,
    stack: error.stack,
    pathName,
  });

  return (
    <Wrapper>
      <Title>Что-то пошло не так.</Title>
      <span>Если ошибка повторится - обратитесь в поддержку</span>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
        style={{ marginTop: '1rem' }}>
        Обновить страницу
      </Button>
    </Wrapper>
  );
};

export default ErrorFallback;
