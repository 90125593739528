import {
  PropsFor,
  style,
  spacing,
  typography,
  palette,
  sizing,
  SizingProps,
  SpacingProps,
  TypographyProps,
  PaletteProps,
} from '@material-ui/system';
import styled from 'styled-components';

const variant = style({
  prop: 'variant',
  cssProperty: false,
  themeKey: 'typography',
});

export const UiTypography = styled.p<
  PropsFor<typeof variant> & TypographyProps & SpacingProps & SizingProps & PaletteProps
>`
  ${variant}
  ${typography}
  ${spacing}
  ${palette}
  ${sizing}
`;
