import React from 'react';

import { CircularProgress, UiBox, UiTypography, UiFlex } from 'shared/ui';

export const LoadingOverlay = ({ enabled, ml }: { enabled?: boolean; ml?: string }) => {
  if (!enabled) return null;
  return (
    <BodyCentered bgcolor="#ffffff80">
      <UiBox ml={ml} textAlign="center">
        <CircularProgress />
        <UiTypography fontWeight="semibold" mb={1}>
          Загрузка...
        </UiTypography>
      </UiBox>
    </BodyCentered>
  );
};

export const BodyCentered = (props: React.ComponentProps<typeof UiFlex>) => {
  return (
    <UiFlex
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      {...props}
    />
  );
};
