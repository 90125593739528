import { observable, action, reaction, computed } from 'mobx';

import { getHousesUsingPOST } from '../api/PrivateOperationController';
import { house } from '../types/PrivateOperationController';

import { RootStore } from './RootStore';

export default class AddressStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    /**
     * Реакция. Запись в localStorage значения housesList
     */
    reaction(
      () => this.houseList,
      (houseList) => {
        if (houseList) {
          window.localStorage.setItem('houseList', JSON.stringify(houseList));
        } else {
          window.localStorage.removeItem('houseList');
        }
      }
    );
  }

  //////// Observables

  /**
   * Список домов УО
   */
  @observable houseList: Array<house> = JSON.parse(`${window.localStorage.getItem('houseList')}`);

  //////// Computes

  /**
   * Отфильтрованный список домов относительно сквозного фильтра по домама
   */
  @computed get filteredHouseList(): Array<house> {
    const { bindUnom } = this.rootStore.userSettingsStore.userSettings;

    if (bindUnom?.length) {
      return this.houseList.filter((el) => bindUnom.includes(el?.unom!));
    }
    return this.houseList;
  }

  /**
   * Общая площадь домов УО
   */
  @computed get totalArea(): number {
    if (this.houseList && this.houseList.length) {
      const sum = this.houseList.reduce((acc, { contract }) => acc + (contract?.totalArea || 0), 0);
      return +sum.toFixed(1);
    }
    return 0;
  }

  //////// Actions

  /**
   *  Метод записывающий список домов УО
   */
  @action setHouseList(value: Array<house>) {
    this.houseList = value;
  }

  /**
   * Метод по получению списка домов
   */
  @action getHouses(employeeId: number) {
    return getHousesUsingPOST({ employeeId }).then((response) => {
      if (response.data.code === 1) {
        if (response.data.houses) {
          this.setHouseList(response.data.houses!);
        }
      }
    });
  }
}
