import { FilledInputProps } from '@material-ui/core';
import { spacing, SpacingProps } from '@material-ui/system';
import formatString from 'format-string-by-pattern';
import { TextField } from 'mui-rff';
import React from 'react';
import styled from 'styled-components';

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  multiline?: boolean;
  inputProps?: Partial<FilledInputProps>;
  style?: Object;
  disabled?: boolean;
} & SpacingProps;

export const StyledPhoneInput = styled(TextField)<Props>`
  ${spacing}
`;

export const PhoneInput = ({
  inputProps,
  label,
  name,
  required = false,
  style,
  mb = 3,
  ...other
}: Props) => {
  return (
    <StyledPhoneInput
      {...other}
      mb={mb}
      name={name}
      required={required}
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      style={style}
      fieldProps={{ parse: formatString('+7 (999) 999 99 99') }}
    />
  );
};
