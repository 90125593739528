import intersection from 'lodash/intersection';
import React from 'react';

interface Props {
  userPermissions: string[];
  requiredPermissions: string[];
  children: JSX.Element;
  renderOtherwise?: JSX.Element;
}

export const PermissibleRender = (props: Props) => {
  const { children, userPermissions, requiredPermissions, renderOtherwise } = props;
  const checkPermissions = () => {
    return intersection(userPermissions, requiredPermissions).length;
  };

  if (!children || !userPermissions || !requiredPermissions) {
    return null;
  }

  if (checkPermissions()) {
    return children;
  } else if (renderOtherwise) {
    return renderOtherwise;
  }
  return null;
};
