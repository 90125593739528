import { createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';
import styled from 'styled-components';

import { CloseIcon as CloseIconMUI } from 'shared/icons';
import { UiTypography, UiFlex } from 'shared/ui';

export let theme = createMuiTheme();

const colorStyle = {
  error: theme.palette.error.main,
  warning: theme.palette.warning.main,
  success: theme.palette.success.main,
};

export interface IToastProps {
  text: string;
  details?: string;
  id: string;
  type?: string;
  delay?: number;

  onClose(id: string): void;
}

export default class Toast extends Component<IToastProps> {
  public static defaultProps = {
    type: 'error',
    delay: 5000,
  };

  public componentDidMount() {
    this.runTimer();
  }

  public render(): React.ReactNode {
    return (
      <Wrapper
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        borderColor={colorStyle[this.props.type!]}
        {...this.props}>
        <UiFlex flexDirection="column" alignItems="center" width="100%">
          <UiTypography color={colorStyle[this.props.type!]}>{this.props.text || ''}</UiTypography>
          <UiTypography color={colorStyle[this.props.type!]}>
            {this.props.details || ''}
          </UiTypography>
        </UiFlex>
        <CloseIcon onClick={this.handleOnCloseClick} iconсolor={colorStyle[this.props.type!]} />
      </Wrapper>
    );
  }

  private timerId = 0;

  private runTimer = (delay?: number) => {
    this.timerId = setTimeout(() => {
      this.handleOnCloseClick();
    }, delay || this.props.delay);
  };

  private handleMouseOver = () => {
    clearTimeout(this.timerId);
  };

  private handleMouseOut = () => {
    this.runTimer(1000);
  };

  private handleOnCloseClick = () => {
    const { id } = this.props;

    if (this.props.onClose) {
      this.props.onClose(id);
    }
  };
}

const Wrapper = styled.div`
  max-width: 1220px;
  min-width: 350px;
  width: fit-content;
  // height: 70px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  display: flex;
  border: ${(props: { borderColor?: string }) => `1px solid ${props.borderColor}`};
  align-items: center;
  //padding-left: 30px;
  //padding-right: 30px;
  padding: 24px 30px;
  margin-bottom: 15px;
  user-select: none;
  position: relative;
`;

const CloseIcon = styled(CloseIconMUI)`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: ${(props: { iconсolor?: string }) => props.iconсolor};
`;
