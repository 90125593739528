import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { DragHandleIcon } from 'shared/icons';
import { PermissibleRender } from 'shared/layout';
import { Switch, Button, UiFlex, UiTypography, UiPaper } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';
import { WidgetControl } from 'types/custom';
import { Organizations } from 'types/publicController';

const reorder = <T extends any>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type WidgetSettingProps = {
  onSave: () => void;
};

export const WidgetSetting = observer((props: WidgetSettingProps) => {
  const { authStore, userSettingsStore } = useContext(rootStoreContext);
  const { userSettings } = userSettingsStore;
  const { ui } = userSettings;
  const { organizationType } = authStore;
  const isFKR = organizationType === Organizations.FKR;
  const [state, setState] = useState<WidgetControl[]>([]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setState(reorder<WidgetControl>(state, result.source.index, result.destination.index));
  };

  const toggleWidget = (e: ChangeEvent<HTMLInputElement>) => {
    const arr = Array.from(state);
    const foundIndex = arr.findIndex((x) => x.name == e.target.name);
    arr[foundIndex].enabled = e.target.checked;
    setState(arr);
  };

  const handleSave = () => {
    const ui = JSON.stringify(state);
    userSettingsStore
      .modifyUserSettings({ userSetting: { ...userSettings, ui }, types: ['UI'] })
      .then((response) => {
        if (response.data.code === 1) {
          userSettingsStore.setUserSettingsValueByKey('ui', ui);
        }
      })
      .finally(() => {
        props.onSave();
      });
  };

  useEffect(() => {
    if (ui) {
      const uiParsedDefault = JSON.parse(ui);
      const uiUO = uiParsedDefault.filter((el: WidgetControl) => el.name !== 'treatments');
      const uiFKR = uiParsedDefault.filter((el: WidgetControl) => el.name !== 'soesg');

      setState(isFKR ? uiFKR : uiUO);
    }
  }, []);

  return (
    <>
      <UiTypography variant="body1" mb={3}>
        Отображение виджетов рабочего стола
      </UiTypography>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {state.map((control: WidgetControl, index: number) => (
                <PermissibleRender
                  key={control.name}
                  userPermissions={userSettingsStore.widgetPermissions[control.name]}
                  requiredPermissions={authStore.requiredPermissions}>
                  <Draggable draggableId={control.name} index={index}>
                    {(provided) => (
                      <UiPaper
                        p={1}
                        mb={1 / 2}
                        display="flex"
                        alignItems="center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        <UiFlex {...provided.dragHandleProps}>
                          <DragHandleIcon />
                        </UiFlex>
                        <Switch
                          checked={control.enabled}
                          onChange={toggleWidget}
                          color="primary"
                          name={control.name}
                        />
                        <UiTypography variant="body1">{control.title}</UiTypography>
                      </UiPaper>
                    )}
                  </Draggable>
                </PermissibleRender>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        style={{ marginTop: 36 }}
        variant="contained"
        size="small"
        color="primary"
        onClick={handleSave}>
        Сохранить
      </Button>
    </>
  );
});
