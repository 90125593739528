import { FilledInputProps } from '@material-ui/core';
import { spacing, SpacingProps } from '@material-ui/system';
import formatString from 'format-string-by-pattern';
import { TextField } from 'mui-rff';
import React from 'react';
import styled from 'styled-components';

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  multiline?: boolean;
  inputProps?: Partial<FilledInputProps>;
  style?: Object;
} & SpacingProps;

export const StyledPassportNumberInput = styled(TextField)<Props>`
  ${spacing}
`;

export const PassportNumberInput = ({
  inputProps,
  label,
  name,
  required = false,
  style,
  mb = 3,
  ...other
}: Props) => {
  return (
    <StyledPassportNumberInput
      {...other}
      mb={mb}
      name={name}
      required={required}
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      style={style}
      fieldProps={{ parse: formatString('77 77 666666'), min: 12 }}
    />
  );
};
