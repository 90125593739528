import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import RouteContainer from 'routes/RouteContainer';
import { UiFlex, LoadingBox } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';

import { PersonalDataProcessModal } from '../misc/PersonalDataProcessModal';
import { SideMenu } from '../SideMenu';

const Main = observer(() => {
  const { authStore } = useContext(rootStoreContext);

  return (
    <LoadingBox isLoading={authStore.isPending}>
      <UiFlex>
        <SideMenu />
        <RouteContainer />
        <PersonalDataProcessModal />
      </UiFlex>
    </LoadingBox>
  );
});

export default Main;
