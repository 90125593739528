import { observer } from 'mobx-react-lite';
import React, { lazy, LazyExoticComponent, Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Switch, withRouter } from 'react-router';

import ErrorFallback from 'components/ErrorFallback';
import { PageContainer } from 'components/PageContainer';
import NotificationsTs from 'containers/NotificationsTs';
import { LoadingOverlay } from 'shared/ui/ReactTable/components';
import { rootStoreContext } from 'stores/RootStore';
import { Organizations } from 'types/publicController';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Login = lazy(() => import('containers/Login'));
const OrganizationDetails = lazy(() => import('containers/OrganizationDetails'));
/* Временно скрыто */
/*const OperatorDetails = lazy(() => import('containers/OperatorDetails'));*/
const UsersRegistry = lazy(() => import('containers/UsersRegistry'));
const UserModify = lazy(() => import('containers/UserModify'));
const Dashboard = lazy(() => import('containers/Dashboard'));
const NewsRegistry = lazy(() => import('containers/NewsRegistry'));
const NewsModify = lazy(() => import('containers/NewsModify'));
const NewsRegistryFKR = lazy(() => import('containers/NewsRegistryFKR'));
const NewsModifyFKR = lazy(() => import('containers/NewsModifyFKR'));
const ExternalNews = lazy(() => import('containers/NewsModify/ExternalNews'));
const TreatmentsRegistry = lazy(() => import('containers/TreatmentsRegistry'));
const TreatmentsRegistryFKR = lazy(() => import('containers/TreatmentsRegistryFKR'));
const Treatment = lazy(() => import('containers/Treatment'));
const SOESGIssuesRegistry = lazy(() => import('containers/Issues/SOESG/Registry'));
const SOESGIssue = lazy(() => import('containers/Issues/SOESG/Issue'));
const PollsAndOss = lazy(() => import('containers/PollsAndOSSTable'));
const PollsAndOssCard = lazy(() => import('containers/PollsAndOSSCard'));
const PollsAndOssCreate = lazy(() => import('containers/PollsAndOSSCreate'));
const OSSProtocols = lazy(() => import('containers/OSSProtocols'));
const OSSAddVote = lazy(() => import('containers/OSSAddVote'));
const OSSRegistry = lazy(() => import('containers/OSSRegistry'));
const OwnerActivity = lazy(() => import('containers/OwnersActivity'));
const OSSTableFKR = lazy(() => import('containers/OSSTableFKR'));
const OSSTableMZHI = lazy(() => import('containers/OSSTableMZHI'));

type ComponentType =
  | React.ComponentType
  | (() => JSX.Element)
  | LazyExoticComponent<React.ComponentType<any>>;
interface Route {
  path: string | string[];
  public: boolean;
  component: ComponentType;
  exact: boolean;
  permissions?: string[];
}

// Список роутов доступный для Управляющих организаций
const routesUO: Route[] = [
  {
    path: '/login',
    public: true,
    component: Login,
    exact: false,
  },
  {
    path: '/',
    public: false,
    component: Dashboard,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '/organization-details',
    public: false,
    component: OrganizationDetails,
    exact: false,
    permissions: ['DEFAULT'],
  },
  /* Временно скрыто */
  /*  {
    path: '/operator-details',
    public: false,
    component: OperatorDetails,
    exact: false,
  },*/
  {
    path: '/users',
    public: false,
    component: UsersRegistry,
    exact: true,
    permissions: ['UK_EMPLOYEE_RESPONSIBILITY'],
  },
  {
    path: ['/users/modify/:employeeId?'],
    public: false,
    component: UserModify,
    exact: false,
    permissions: ['UK_EMPLOYEE_RESPONSIBILITY'],
  },
  {
    path: '/treatments',
    public: false,
    component: TreatmentsRegistry,
    exact: true,
    permissions: ['UK_TREATMENTS_READ'],
  },
  {
    path: '/treatments/card/:id',
    public: false,
    component: Treatment,
    exact: false,
    permissions: ['UK_TREATMENTS_READ', 'UK_TREATMENTS_EDIT'],
  },
  {
    path: '/news',
    public: false,
    component: NewsRegistry,
    exact: true,
    permissions: ['UK_NEWS_READ'],
  },
  {
    path: ['/news/modify/:id?'],
    public: false,
    component: NewsModify,
    exact: false,
    permissions: ['UK_NEWS_READ', 'UK_NEWS_EDIT'],
  },
  {
    path: ['/news/external/:id'],
    public: false,
    component: ExternalNews,
    exact: false,
    permissions: ['UK_NEWS_READ'],
  },
  {
    path: '/polls-oss',
    public: false,
    component: PollsAndOss,
    exact: true,
    permissions: ['UK_OSS_READ'],
  },
  {
    path: '/polls-oss/create',
    public: false,
    component: PollsAndOssCreate,
    exact: false,
    permissions: ['UK_OSS_EDIT', 'UK_POLL_EDIT'],
  },
  {
    path: '/polls-oss/card/:id',
    public: false,
    component: PollsAndOssCard,
    exact: true,
    permissions: ['UK_OSS_READ'],
  },
  {
    path: '/polls-oss/card/:id/uploads',
    public: false,
    component: OSSRegistry,
    exact: false,
    //TODO FIX PERMISSIONS
    permissions: ['UK_PROTOCOL_OSS_READ'],
  },
  {
    path: '/polls-oss/owner-activity/:id',
    public: false,
    component: OwnerActivity,
    exact: false,
    permissions: ['UK_OSS_READ'],
  },
  {
    path: '/oss-protocols',
    public: false,
    component: OSSProtocols,
    exact: true,
    permissions: ['UK_PROTOCOL_OSS_READ'],
  },
  {
    path: '/soesg-issues',
    public: false,
    component: SOESGIssuesRegistry,
    exact: true,
    permissions: ['UK_ISSUES_READ'],
  },
  {
    path: '/soesg-issues/card/:id',
    public: false,
    component: SOESGIssue,
    exact: false,
    permissions: ['UK_ISSUES_READ'],
  },
  {
    path: '/add-vote/card/:id/:type',
    public: false,
    component: OSSAddVote,
    exact: false,
    permissions: ['UK_ISSUES_READ'],
  },
  {
    path: '/notifications-ts',
    public: false,
    component: NotificationsTs,
    exact: true,
    permissions: ['UK_OSS_READ'],
  },
  {
    path: '*',
    public: true,
    component: () => (
      <PageContainer>
        <div>Страница не найдена</div>
      </PageContainer>
    ),
    exact: false,
  },
];

// Список роутов доступный для Фондов капитального ремонта
const routesFKR: Route[] = [
  {
    path: '/login',
    public: true,
    component: Login,
    exact: false,
  },
  {
    path: '/',
    public: false,
    component: Dashboard,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '/organization-details',
    public: false,
    component: OrganizationDetails,
    exact: false,
    permissions: ['DEFAULT'],
  },
  {
    path: '/treatments',
    public: false,
    component: TreatmentsRegistryFKR,
    exact: true,
    permissions: ['FKR_TREATMENTS_READ'],
  },
  {
    path: '/treatments/card/:id',
    public: false,
    component: Treatment,
    exact: false,
    permissions: ['FKR_TREATMENTS_READ', 'FKR_TREATMENTS_EDIT'],
  },
  // Скрыто до дальнейшего распоряжения
  // {
  //   path: '/news',
  //   public: false,
  //   component: NewsRegistryFKR,
  //   exact: true,
  //   permissions: ['UK_NEWS_READ'],
  // },
  // {
  //   path: ['/news/modify/:id?'],
  //   public: false,
  //   component: NewsModifyFKR,
  //   exact: false,
  //   permissions: ['UK_NEWS_READ', 'UK_NEWS_EDIT'],
  // },
  // {
  //   path: ['/news/external/:id'],
  //   public: false,
  //   component: ExternalNews,
  //   exact: false,
  //   permissions: ['DEFAULT'],
  // },
  {
    path: '/polls-oss',
    public: false,
    component: OSSTableFKR,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '/polls-oss/card/:id',
    public: false,
    component: PollsAndOssCard,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '*',
    public: true,
    component: () => (
      <PageContainer>
        <div>Страница не найдена</div>
      </PageContainer>
    ),
    exact: false,
  },
];

// список роутов МЖИ
// TO DO: поправить permissions
const routesMZHI: Route[] = [
  {
    path: '/login',
    public: true,
    component: Login,
    exact: false,
  },
  {
    path: '/',
    public: false,
    component: Dashboard,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '/organization-details',
    public: false,
    component: OrganizationDetails,
    exact: false,
    permissions: ['DEFAULT'],
  },
  // TO DO: Новости будут реализованы в следующей итерации
  // {
  //   path: '/news',
  //   public: false,
  //   component: NewsRegistry,
  //   exact: true,
  //   permissions: ['DEFAULT'],
  // },
  // {
  //   path: ['/news/modify/:id?'],
  //   public: false,
  //   component: NewsModify,
  //   exact: false,
  //   permissions: ['DEFAULT'],
  // },
  // {
  //   path: ['/news/external/:id'],
  //   public: false,
  //   component: ExternalNews,
  //   exact: false,
  //   permissions: ['DEFAULT'],
  // },
  {
    path: '/polls-oss',
    public: false,
    component: OSSTableMZHI,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '/polls-oss/card/:id',
    public: false,
    component: PollsAndOssCard,
    exact: true,
    permissions: ['DEFAULT'],
  },
  {
    path: '*',
    public: true,
    component: () => (
      <PageContainer>
        <div>Страница не найдена</div>
      </PageContainer>
    ),
    exact: false,
  },
];

const routesList = {
  [Organizations.UO]: routesUO,
  [Organizations.FKR]: routesFKR,
  [Organizations.MZHI]: routesMZHI,
} as const;

const RouteContainer = observer(() => {
  const { authStore, userSettingsStore } = useContext(rootStoreContext);
  const { isLoggedIn, organizationType } = authStore;
  const { sideMenuIsCollapsed } = userSettingsStore;

  const routes = routesList[organizationType] || routesList[Organizations.UO];

  return (
    <Suspense
      fallback={
        <LoadingOverlay enabled ml={isLoggedIn && !sideMenuIsCollapsed ? '280px' : '0px'} />
      }>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Switch>
          {routes.map((route, i) => {
            if (route.public) {
              return <PublicRoute key={i} path={route.path} component={route.component} />;
            }
            return (
              <PrivateRoute
                userPermissions={route.permissions!}
                key={i}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </ErrorBoundary>
    </Suspense>
  );
});

export default withRouter(RouteContainer);
