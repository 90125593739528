import { styled } from '@material-ui/core/styles';
import { compose, spacing, SpacingProps } from '@material-ui/system';
import React, { ReactNode } from 'react';

import { Tooltip } from 'shared/ui';

type Props = {
  tipText: NonNullable<ReactNode>;
  icon: ReactNode;
  style?: object;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  disabled?: boolean;
  onClick?: () => void;
} & SpacingProps;

export const StyledTooltip = styled(Tooltip)(compose(spacing));

export const TooltipIcon = ({
  tipText,
  disabled,
  icon,
  mt = 1,
  style,
  onClick = () => {},
  placement = 'top',
  ...other
}: Props) => (
  <StyledTooltip
    title={tipText}
    PopperProps={{ style: { zIndex: 1100 } }}
    arrow
    placement={placement}
    disableHoverListener={disabled}
    interactive
    mt={mt}
    style={style}
    onClick={onClick}
    {...other}>
    <div>{icon}</div>
  </StyledTooltip>
);
