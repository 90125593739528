import { format } from 'date-fns';

import { TimeValue } from './types';

export const getTimeValues = () => {
  const result: { key: number; value: string }[] = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
      const m = j === 0 ? `00` : 30 * j;
      result.push({
        key: Number.parseInt(`${i}${m}`, 10),
        value: `${i < 10 ? `0${i}` : i}:${m}`,
      });
    }
  }

  return result;
};

export const getCurrentTimeValue = () => {
  const d = new Date();
  const h = d.getHours();
  const m = 30 * Math.floor(d.getMinutes() / 30);
  const _h = `${h < 10 ? `0${h}` : h}`;
  const _m = `${m === 0 ? `00` : m}`;
  return {
    key: Number.parseInt(`${_h}${_m}`, 10),
    value: `${_h}:${_m}`,
  };
};

export const checkTimeTo = (from?: TimeValue['key'], to?: TimeValue['key']) => {
  if (typeof from !== 'number' || typeof to !== 'number') {
    return false;
  }

  if (from > to) {
    return false;
  }

  return true;
};

export const checkCurrentDate = (date?: string) => {
  return format(new Date(), 'yyyy.MM.dd') === date;
};

export const checkTimeFromByDate = (date?: string, from?: TimeValue['key']) => {
  if (!date || typeof from !== 'number') {
    return false;
  }

  if (!checkCurrentDate(date)) {
    return true;
  }

  const current = getCurrentTimeValue();

  if (current.key > from) {
    return false;
  }

  return true;
};

export const timeList = getTimeValues();
export const timeListKeys = timeList.reduce(
  (result, item) => ({
    ...result,
    [item.key]: item.value,
  }),
  {}
);
