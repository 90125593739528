import { spacing, SpacingProps } from '@material-ui/system';
import { TextField } from 'mui-rff';
import React from 'react';
import styled from 'styled-components';

type Props = { label: string; name: string; required?: boolean; style?: Object } & SpacingProps;

export const StyledTimePicker = styled(TextField)<Props>`
  ${spacing}
`;

export const TimePicker = ({ label, name, required = false, style, mb = 3, ...other }: Props) => (
  <StyledTimePicker
    {...other}
    mb={mb}
    name={name}
    variant="outlined"
    fullWidth
    required={required}
    style={style}
    size="small"
    type="time"
    label={label}
    InputLabelProps={{
      shrink: true,
    }}
    inputProps={{
      step: 300, // 5 min
    }}
  />
);
