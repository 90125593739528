import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'theme';

export const Link = styled(NavLink)`
  color: inherit;
  font-size: 13px;
  line-height: inherit;
  &.active {
    font-weight: bold;
    & > div {
      background: rgba(57, 199, 222, 0.05);
      & svg {
        color: ${theme.palette.primary.main};
      }
    }
  }
`;

export const ExpandButton = styled('div')`
  position: fixed;
  top: 50%;
  margin-left: ${(props: { expanded: boolean }) => (props.expanded ? '280px' : '0')};
  box-shadow: 4px 0 7px 0 #3d748f1a;
  z-index: 1100;
  height: 28px;
  width: 20px;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
  padding: 0 2px;
  cursor: pointer;
  transition: ${(props: { expanded: boolean }) =>
    theme.transitions.create('margin', {
      easing: props.expanded ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
      duration: props.expanded
        ? theme.transitions.duration.leavingScreen
        : theme.transitions.duration.enteringScreen,
    })};
  & svg {
    margin-left: -6px;
    transform: ${(props: { expanded: boolean }) => (props.expanded ? 'scaleX(-1)' : 'scaleX(1)')};
  }
`;

export const StyledInstructionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  & span {
    display: flex;
    align-items: center;
    gap: 10px;
    & p {
      font-family: -apple-system, BlinkMacSystemFont, HelveticaNeue, sans-serif;
      font-size: 12px;
      line-height: 12px;
      color: #39c7de;
    }
  }
`;
