import React, { Component, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { v1 as uuid } from 'uuid';

import Toast, { IToastProps } from './Toast';
import ToastContainer from './ToastContainer';

const context = React.createContext({});
const { Provider } = context;

interface IToastProviderProps {
  toast?: any;
}

interface IToastProviderPropsState {
  toasts: any[];
}

export const { Consumer: ToastConsumer } = context;

export const ToastProvider = class extends Component<
  IToastProviderProps,
  IToastProviderPropsState
> {
  public state = {
    toasts: [],
  };

  public componentDidUpdate(oldProps: IToastProviderProps) {
    const newProps = this.props;

    if (oldProps.toast.id !== newProps.toast.id) {
      this.setState((state) => {
        return {
          toasts: [{ ...newProps.toast }, ...state.toasts],
        };
      });
    }
  }

  public render(): ReactNode {
    return (
      <Provider value={{ add: this.handleAddToast }}>
        {this.props.children}
        {this.renderToasts()}
      </Provider>
    );
  }

  private handleAddToast = (toast: IToastProps) => {
    const id = uuid();
    // Добавляем новый тост в массив отображаемых тостов и присваиваем ему уникальный ID
    // ID нужен для удаления по клику
    this.setState((prevState) => {
      return {
        toasts: [{ ...toast, id }, ...prevState.toasts],
      };
    });
  };

  private handleCloseToast = (id: string) => {
    this.setState((prevState) => {
      return {
        toasts: [...prevState.toasts.filter((toast: IToastProps) => toast.id !== id)],
      };
    });
  };

  private renderToasts = () => {
    const { toasts } = this.state;

    return ReactDOM.createPortal(
      <ToastContainer>
        {toasts.map((toast: IToastProps) => {
          return (
            <Toast
              key={toast.id}
              id={toast.id}
              text={toast.text}
              details={toast.details}
              delay={toast.delay}
              type={toast.type}
              onClose={this.handleCloseToast}
            />
          );
        })}
      </ToastContainer>,
      document.body
    );
  };
};
