import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import { useTheme } from 'shared/hooks';
import { BoxProps, Box } from 'shared/ui';
import { rootStoreContext } from 'stores/RootStore';

export const PageContainer = observer((props: BoxProps) => {
  const { authStore } = useContext(rootStoreContext);

  return (
    <ResponsivePageContainer bgcolor={props.bgcolor} pb={3}>
      <Box
        maxWidth={authStore.isLoggedIn ? '1280px' : '100%'}
        flexGrow={1}
        height="100%"
        {...props}
      />
    </ResponsivePageContainer>
  );
});

export const ResponsivePageContainer = observer((props: BoxProps) => {
  const { userSettingsStore, authStore } = useContext(rootStoreContext);
  const { isLoggedIn } = authStore;
  const { sideMenuIsCollapsed } = userSettingsStore;
  const theme = useTheme();
  return (
    <Box
      ml={isLoggedIn && !sideMenuIsCollapsed ? 35 : 0}
      style={{
        transition: theme.transitions.create('margin', {
          easing: sideMenuIsCollapsed
            ? theme.transitions.easing.easeOut
            : theme.transitions.easing.sharp,
          duration: sideMenuIsCollapsed
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen,
        }),
      }}
      minHeight="calc(100vh - 64px)"
      mt={8}
      flexGrow={1}
      p={4}
      pb={0}
      overflow="hidden"
      {...props}
    />
  );
});
