import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useContext } from 'react';

import { ToastProvider } from 'components/Toast/ToastManager';
import { rootStoreContext } from 'stores/RootStore';

const Notifications: FunctionComponent = observer(() => {
  const { commonStore } = useContext(rootStoreContext);

  return (
    <div>
      <ToastProvider toast={commonStore.notification} />
    </div>
  );
});

export default Notifications;
