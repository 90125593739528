import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import ruLocale from 'date-fns/locale/ru';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { Route } from 'react-router-dom';
import { ThemeProvider as SSThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import { useSendMetricByPath } from 'hooks/useSendMetric';
import { queryClient } from 'shared';

import { Header } from './containers/Header';
import IdleDetector from './containers/Idle';
import Main from './containers/Main';
import Notify from './containers/Notify';
import { GlobalStyle } from './globalStyles';
import { theme } from './theme';

const queryConfig = { queries: { refetchOnWindowFocus: false, retry: false, cacheTime: 0 } };

const App = () => {
  // отправка метрики
  useSendMetricByPath(window.location.href);

  return (
    <SSThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryConfigProvider config={queryConfig}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <CssBaseline />
                <GlobalStyle />
                <Header />
                <Main />
                <Notify />
                <IdleDetector />
              </MuiPickersUtilsProvider>
            </QueryParamProvider>
          </ReactQueryConfigProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </SSThemeProvider>
  );
};

export default App;
