export const administratorDit = {
  name: 'Департамент информационных технологий Москвы',
  organizationType: 'ДИТ',
  factualAddress: '1-й Красногвардейский пр-д, д.21, стр.1',
  postAddress: '1-й Красногвардейский пр-д, д.21, стр.1',
  phoneNumber: '+7 (495) 957-01-31',
  organizationSite: 'https://www.mos.ru/dit/',
  opf: '',
};

export const ymId = 61866366;
